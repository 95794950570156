(function ($) {
    $(document).on('change', '.checkbox-all', function (e) {
        let status = this.checked;
        $('.item-checkbox').each(function(){
            this.checked = status;
        });
    });
    $(document).on('change', '#cateSupperApp', function (e) {
        let value = $(this).val();
        $('.kindAll select').val('');
        $('.categorySA select').val('');
        if (value == 2){
            $('.kindAll').addClass('d-none');
            $('.kindAll select').attr('disabled', 'disabled');
            $('.categorySA').removeClass('d-none');
            $('.categorySA select').removeAttr('disabled');
        }else{
            $('.categorySA').addClass('d-none');
            $('.categorySA select').attr('disabled', 'disabled');
            $('.kindAll').removeClass('d-none');
            $('.kindAll select').removeAttr('disabled');
        }
    });
    // Supper App checkbox video
    // $(document).on('change', '#configSupperApp-checkbox-video', function (e) {
    //     e.preventDefault();
    //     let href = window.location.href;
    //     let value = $(this).val();
    //     let checkbox = [];
    //     $('input[name="checkbox_video[]"]:checked').each(function(){
    //         // checkbox = checkbox + ',' + $(this).val();
    //         checkbox.push($(this).val());
    //     });
    //     let title = '';
    //     let text = '';
    //     if (value == 1){
    //         title = 'Hiện thị trang chủ';
    //         text = 'Bạn muốn hiện các video trên trang chủ supper app?';
    //     }else if (value == 3){
    //         title = 'Play trên supper app';
    //         text = 'Bạn muốn play các video trên supper app?';
    //     }else if (value == 4){
    //         title = 'Dừng phát trên supper app';
    //         text = 'Bạn muốn dừng phát các video trên supper app?';
    //     }else if (value == 5){
    //         title = 'Hiện thị tab tất cả';
    //         text = '';
    //     }else if (value == 6){
    //         title = 'Ẩn tab tất cả';
    //         text = '';
    //     }else if (value == 7){
    //         title = 'Hiện tab thịnh hành';
    //         text = '';
    //     }else if (value == 8){
    //         title = 'Ẩn tab thịnh hành';
    //         text = '';
    //     }else{
    //         title = 'Ẩn trên trang chủ';
    //         text = 'Bạn muốn ẩn các video trên trang chủ supper app?';
    //     }
    //
    //     if (checkbox.length)
    //     {
    //         Swal.fire({
    //             title: title,
    //             text: text,
    //             type: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#3085d6',
    //             cancelButtonColor: '#d33',
    //             confirmButtonText: 'Yes',
    //             cancelButtonText: 'No',
    //         }).then((result) => {
    //             if (result.value) {
    //                 $.ajaxSetup({
    //                     headers: {
    //                         'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    //                     }
    //                 });
    //                 $.ajax(BASE_URL + '/supper-app/action',{
    //                     type: 'POST',
    //                     dataType: 'json',
    //                     data: {
    //                         value: value,
    //                         ids: checkbox,
    //                     },
    //                     success:function (data){
    //                         console.log(data);
    //                         if (data.error){
    //                             Swal.fire({
    //                                 type: 'error',
    //                                 title: 'Error',
    //                                 text: data.error,
    //                             })
    //                         }
    //                         else if (data.success) {
    //                             Swal.fire(
    //                                 'Thành công!',
    //                                 data.success,
    //                                 'success'
    //                             ).then((value) => {
    //                                 $(location).attr('href', href);
    //                             });
    //                         }
    //                         else {
    //                             $('.footer .overlay').removeClass('overlaing');
    //                             Swal.fire(
    //                                 'Error!',
    //                                 data.logged,
    //                                 'error'
    //                             ).then((value) => {
    //                                 $(location).attr('href', BASE_URL);
    //                             });
    //                         }
    //                     }
    //                 });
    //             }
    //         });
    //     }else{
    //         Swal.fire({
    //             type: 'error',
    //             title: 'Error',
    //             text: 'Hãy chọn video!',
    //         });
    //     }
    //     $('select[name="configSupperApp-checkbox-video"]').selectpicker('val', '');
    // });
    $(document).on('click', '#btn_configSupperApp-checkbox-video', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let value = $('#configSupperApp-checkbox-video').val();
        let checkbox = [];
        let str = '';
        $('input[name="checkbox_video[]"]:checked').each(function(){
            checkbox.push($(this).val());
        });

        if (checkbox.length == 0){
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy chọn video!',
            });
        }else if (value.length == 0){
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy chọn hành động!',
            });
        }else{
            for (var i = 0; i < value.length; i++){
                if (i == 0){
                    str += value[i];
                }else{
                    str += ',' + value[i];
                }
            }
            Swal.fire({
                title: 'Action',
                text: 'Bạn muốn thực hiện các hành động ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Thoát',
            }).then((result) => {
                if (result.value) {
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });
                    $.ajax(BASE_URL + '/supper-app/action',{
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            value: str,
                            ids: checkbox,
                        },
                        success:function (data){
                            console.log(data);
                            if (data.error){
                                Swal.fire({
                                    type: 'error',
                                    title: 'Error',
                                    text: data.error,
                                })
                            }
                            else if (data.success) {
                                Swal.fire(
                                    'Thành công!',
                                    data.success,
                                    'success'
                                ).then((value) => {
                                    $(location).attr('href', href);
                                });
                                $('select[name="configSupperApp-checkbox-video"]').selectpicker('val', '');
                            }
                            else {
                                $('.footer .overlay').removeClass('overlaing');
                                Swal.fire(
                                    'Error!',
                                    data.logged,
                                    'error'
                                ).then((value) => {
                                    $(location).attr('href', BASE_URL);
                                });
                            }
                        }
                    });
                }
            });
        }
    });
    // Click button Sort Supper App video
    $(document).on('click', '.btn-sort-supperApp', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        let all = $(this).attr('data-all');
        let popular = $(this).attr('data-popular');

        $('#video_sort_supperApp input[name="videoID"]').val(id);
        $('#video_sort_supperApp .modal-title').html(title);
        $('#video_sort_supperApp select[name="sortAll"]').val(all);
        $('#video_sort_supperApp input[name="sortPopular"]').val(popular);

        $('#video_sort_supperApp').modal('show');
    });

    // Submit Form Show Supper App
    $('.form-sort-supper-app').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            let href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });

    // Click button Table Supper App
    $(document).on('click', '.btn-supperApp', function (e) {
        e.preventDefault();
        $('#show_table_supperApp .modal-title').html('');
        $('#show_table_supperApp .modal-body table tbody').html('');

        let title = $(this).attr('data-title');
        let home = $(this).attr('data-home');
        let all = $(this).attr('data-all');
        let popular = $(this).attr('data-popular');
        let play = $(this).attr('data-play');
        let html = '';
        //Home
        html += '<tr><td>Trang chủ</td><td class="text-center">'+ home +'</td></tr>';
        //All
        html += '<tr><td>Tab tất cả</td><td class="text-center">'+ all +'</td></tr>';
        //Popular
        html += '<tr><td>Tab thịnh hành</td><td class="text-center">'+ popular +'</td></tr>';

        html += '<tr><td>Play</td><td class="text-center">'+ play +'</td></tr>';

        $('#show_table_supperApp .modal-title').html(title);
        $('#show_table_supperApp .modal-body table tbody').html(html);

        $('#show_table_supperApp').modal('show');
    });
})(jQuery);
