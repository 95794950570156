(function ($) {
    //Hủy luồng upload video
    // $('.video__thumb .thumb').imagepicker();
    $(document).on('click','.upload--cancel',function () {
        let uploadId = $('.box input[name="uploadId"]').val();
        console.log(uploadId);
        console.log(uploadId.length);
        if (uploadId.length > 0){
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/video-manager/delete-file',{
                type: 'POST',
                dataType: 'json',
                data: {
                    uploadId: uploadId
                },
                success:function (data){
                    console.log(data);
                    $('.footer .overlay').removeClass('overlaing');
                    if (data.error){
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: data.error,
                        });
                    }
                    else {
                        $('.box .upload-video-step-1 form input[type="file"]').addClass('cancel');
                        cancelVideo();
                        $('.box .upload-video-step-1').remove();

                        $('.upload-video .upload-video-step-1').each(function( index ) {
                            $(this).clone().appendTo('.box');
                        });
                        $('.box input[name="token_trans"]').val('');
                        $('.box input[name="originUrl"]').val('');
                        $('.box input[name="previewUrl"]').val('');
                        $('.box input[name="uploadId"]').val('');
                        $('.box input[name="duration"]').val('');
                        $('.box input[name="thumbs1"]').val('');
                        $('.box input[name="thumbs2"]').val('');
                        $('.box input[name="create_title_video"]').val('');
                        $('.box select[name="create_kind_video"]').val('');
                        $('.box select[name="create_channel_video"]').val('');
                        $('.box textarea[name="create_des_video"]').val('');
                        $('.box input[name="create_hashtag_video"]').val('');

                        $('.box .btn-create-content-video').removeAttr('disabled');

                        $('.btn-upload-show-video').attr('data-originalUrl', '');
                        $('.btn-upload-show-video').css('display', 'none');

                        $('.box .upload-video-step-2').hide();
                        $('.box .upload-video-step-1').show();
                        Swal.fire({
                            type: 'warning',
                            text: 'Luồng upload đã bị hủy. Vui lòng tạo luồng mới!',
                        });
                    }
                }
            });
        }else{
            $('.box .upload-video-step-1 form input[type="file"]').addClass('cancel');
            cancelVideo();
            $('.box .upload-video-step-1').remove();

            $('.upload-video .upload-video-step-1').each(function( index ) {
                $(this).clone().appendTo('.box');
            });
            $('.box input[name="token_trans"]').val('');
            $('.box input[name="originUrl"]').val('');
            $('.box input[name="previewUrl"]').val('');
            $('.box input[name="uploadId"]').val('');
            $('.box input[name="duration"]').val('');
            $('.box input[name="thumbs1"]').val('');
            $('.box input[name="thumbs2"]').val('');
            $('.box input[name="create_title_video"]').val('');
            $('.box select[name="create_kind_video"]').val('');
            $('.box select[name="create_channel_video"]').val('');
            $('.box textarea[name="create_des_video"]').val('');
            $('.box input[name="create_hashtag_video"]').val('');

            $('.box .btn-create-content-video').removeAttr('disabled');

            $('.btn-upload-show-video').attr('data-originalUrl', '');
            $('.btn-upload-show-video').css('display', 'none');

            $('.box .upload-video-step-2').hide();
            $('.box .upload-video-step-1').show();
            Swal.fire({
                type: 'warning',
                text: 'Luồng upload đã bị hủy. Vui lòng tạo luồng mới!',
            });
        }
    });

    //Click btn add video
    $(document).on('click', '.btn-add-video', function (e) {
        e.preventDefault();
        $('.footer .overlay').addClass('overlaing');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/video-manager/getToken',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (data){
                console.log(data);
                $('.footer .overlay').removeClass('overlaing');
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                    $('#addnew_video').modal('hide');
                }
                else if(data.success) {
                    $('.box .upload-video-step-1').show();
                    $('.box .upload-video-step-2').hide();
                    // $('.box .upload-video-step-1 input[name="token_upload"]').val(data.success);
                    $('.upload-video-step-1 input[name="file_video"]').attr('data-token', data.success);
                    // $('.upload-video-step-1 input[name="token_upload"]').val(data.success);
                    $('#addnew_video').modal('show');
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });

    // Search video account CP (Create video)
    $(document).on('change', '#video_list_cp', function (e) {
        e.preventDefault();
        $('.video-account-cp div').remove();
        $('#video_create_channel_cp option').remove();
        var $this = $(this);
        var id = $('#video_list_cp').val();
        console.log(id);
        $('.video-account-cp').html('<select name="staffId_acc_cp" id="video_list_acc_cp" required data-msg="Chưa chọn tài khoản" class="form-control" data-live-search="true" title="Chọn tài khoản CP"></select>');

        //AJAX get info CP
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/video-manager/search-info-cp',{
            type: 'POST',
            dataType: 'json',
            data: {
                id: id,
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    $('#video_list_acc_cp').removeAttr('disabled');
                    $('#video_list_acc_cp').attr('title', data.error);
                    $('#video_list_acc_cp').selectpicker();
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else if(data.success) {
                    $('#video_list_acc_cp').attr('title', 'Chọn tài khoản CP');
                    $('#video_list_acc_cp').removeAttr('disabled');
                    $('#video_list_acc_cp').html(data.success.account);
                    $('#video_list_acc_cp').selectpicker();
                    $('#video_create_channel_cp').html(data.success.channel);
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });

    // Search video change type
    $(document).on('change', '#search-video-type', function (e) {
        e.preventDefault();
        let $this = $(this);
        let type = $('#search-video-type').val();
        console.log(type);
        if(type == 0)
        {
            $('.box-search-acc-cp').removeClass('d-none');
            $('.box-search-acc-user').addClass('d-none');
            $('#staffId_cp').removeAttr('disabled', 'disabled');
            $('.box-search-acc-cp button.dropdown-toggle').removeClass('disabled');
            $('#staffId_user').attr('disabled', 'disabled');
        }
        else if (type == 1)
        {
            $('.box-search-acc-user').removeClass('d-none');
            $('.box-search-acc-cp').addClass('d-none');
            $('.box-search-acc-user .dropdown.bootstrap-select').removeClass('disabled');
            $('.box-search-acc-user .dropdown.bootstrap-select button.dropdown-toggle').removeClass('disabled');
            $('#staffId_user').removeAttr('disabled', 'disabled');
            $('#staffId_cp').attr('disabled', 'disabled');
        }
    });

    $(document).on('change', '.list-video-search #staffId_cp', function (e) {
        e.preventDefault();
        let $this = $(this);
        let type = $('#search-video-type').val();
        let id = $('.list-video-search #staffId_cp').val();

        $('.list-video-search #video_search_channel').attr('data-type', type);
        $('.list-video-search #video_search_channel').attr('data-id', id);
    });
    $(document).on('change', '.list-video-search #staffId_user', function (e) {
        e.preventDefault();
        let $this = $(this);
        let type = $('#search-video-type').val();
        let id = $('.list-video-search #staffId_user').val();

        $('.list-video-search #video_search_channel').attr('data-type', type);
        $('.list-video-search #video_search_channel').attr('data-id', id);
    });

    function formatRepo (repo)
    {
        let markup = "<div class='select2-result-repository clearfix'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title'>" + repo.name + "</div></div></div>";
        return markup;
    }
    // Select ajax search channel
    $('.video_search-channel-js-data-user').select2({
        // matcher: matchCustom,
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/video-manager/get-channel-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                let type = $(this).attr('data-type');
                let id = $(this).attr('data-id');

                return {
                    q: params.term, // search term
                    type: type,
                    id: id,
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.content;
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.total
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Chọn kênh',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionChannel
    });

    function formatRepoSelectionChannel (repo) {
        return repo.name || repo.text;
    }

    // Click submit form create content video
    $('.box .btn-create-content-video').click(function (e){
        e.preventDefault();
        let uploadId = $('.box .upload-video-step-2 input[name=uploadId]').val();
        let fileName = $('.box .upload-video-step-2 input[name=fileName]').val();
        let hashtag = $('.box .upload-video-step-2 input[name=create_hashtag_video]').val();
        let hash = hashtag.split(",");
        if (uploadId.length > 0)
        {
            if (fileName.length > 0)
            {
                if (hash.length >= 5)
                {
                    if($('.box .form-create-video').valid() == true) {
                        $('.box .btn-create-content-video').attr('disabled', 'disabled');
                        $('.box .form-create-video').submit();
                    }
                }else{
                    Swal.fire({
                        type: 'warning',
                        title: 'Warning',
                        text: 'Hãy nhập 5 Hashtag trở lên!',
                    });
                }
            }else{
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: 'Chưa có file name!',
                });
            }
        }else{
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Video chưa upload xong!',
            });
        }
    });
    $('.box .form-create-video').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            $('.box .btn-create-content-video').removeAttr('disabled');
            if (res.error){
                $('.box .btn-create-content-video').removeAttr('disabled');
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.box .upload-video-step-2 input[name="uploadId"]').remove();
                $('.box .btn-create-content-video').removeAttr('disabled');
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/video-manager');
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $('.box .video-thumb-upload').dropify({
        messages: {
            'default': 'Hình ảnh thu nhỏ tùy chỉnh',
            'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
            'remove':  'Hủy',
            'error':   'Xin lỗi có lỗi đã xảy ra'
        },
        error: {
            'fileSize': 'Ảnh quá lớn ({{ value }} max).',
            'minWidth': 'The image width is too small ({{ value }}}px).',
            'maxWidth': 'The image width is too big ({{ value }}}px).',
            'minHeight': 'The image height is too small ({{ value }}}px).',
            'maxHeight': 'The image height is too big ({{ value }}px).',
            'imageFormat': 'Định dạng không đúng ({{ value }}).',
            'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
        },
    });

    // Search video
    $(document).on('click', '#video-search', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });

    // Edit video
    $(document).ready(function () {
        let href = window.location.href;
        $('#form-edit-video').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    $('#btn-edit-content-video').removeAttr('disabled', 'disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else if (res.success) {
                    $('.footer .overlay').removeClass('overlaing');
                    $('#btn-edit-content-video').removeAttr('disabled', 'disabled');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $('#edit_video').modal('hide');
                        $(location).attr('href', href);
                    });
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    $('#btn-edit-content-video').removeAttr('disabled', 'disabled');
                    Swal.fire(
                        'Error!',
                        res.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
        $('#btn-edit-content-video').click(function (e){
            e.preventDefault();
            let hashtag = $('#edit_video .form-edit-video input[name=edit_hashtag_video]').val();
            let hash = hashtag.split(",");
            if (hash.length >= 5)
            {
                if($('#form-edit-video').valid() == true) {
                    $('.footer .overlay').addClass('overlaing');
                    $('#btn-edit-content-video').attr('disabled', 'disabled');
                    $('#form-edit-video').submit();
                }
            }else{
                Swal.fire({
                    type: 'warning',
                    title: 'Warning',
                    text: 'Hãy nhập 5 Hashtag trở lên!',
                });
            }
        });
    });
        // Click button edit
    $(document).on('click', '.edit-video', function (e) {
        e.preventDefault();
        $('input[name="edit_hashtag_video"]').tagsinput('removeAll');
        $('select[name="edit_video_thumb"] option').remove();

        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        let kind = $(this).attr('data-kind');
        let channel = $(this).attr('data-channel');
        let channelName = $(this).attr('data-channelName');
        let hashtag = $(this).attr('data-hashtag');
        let des = $(this).attr('data-des');
        let originUrl = $(this).attr('data-originUrl');
        let previewUrl = $(this).attr('data-previewUrl');
        let uploadId = $(this).attr('data-uploadId');
        let duration = $(this).attr('data-duration');
        let thumb = $(this).attr('data-thumb');
        let thumb2 = $(this).attr('data-thumb2');
        let free = $(this).attr('data-free');
        let sortCategory = $(this).attr('data-sortCategory');
        let sortPopular = $(this).attr('data-sortPopular');
        let view = $(this).attr('data-view');
        let share = $(this).attr('data-share');
        let like = $(this).attr('data-like');
        let publishDate = $(this).attr('data-publishDate');
        let packages = $(this).attr('data-packages');
        // console.log(packages);
        $('input[name="edit_mainPackages"]').prop("checked", false);
        if(packages == 2){
            $('#edit_mainPackages2').prop("checked", true);
        }
        else if(packages == 3){
            $('#edit_mainPackages3').prop("checked", true);
        }
        else{
            $('#edit_mainPackages1').prop("checked", true);
        }
        // Check Radio: Play Supper App
        $('input[name="edit_supperApp"]').prop("checked", false);
        if(free == 'true'){
            $('#edit_supperApp1').prop("checked", true);
        } else{
            $('#edit_supperApp2').prop("checked", true);
        }
        let selected = [];
        if (kind.length > 0){
            var cate = JSON.parse(kind);
            for(var i = 0; i < cate.length; i++){
                selected.push(cate[i]['categoryId']);
            }
        }

        $('input[name="edit_id_video"]').val(id);
        $('input[name="edit_title_video"]').val(title);
        $('select[name="edit_kind_video[]"]').selectpicker('val', selected);
        $('#edit_channel_video').val(channelName);
        $('input[name="edit_channel_video"]').val(channel);
        $('input[name="edit_hashtag_video"]').tagsinput('add', hashtag);
        $('textarea[name="edit_des_video"]').val(des);
        $('input[name="edit_originUrl"]').val(originUrl);
        $('input[name="edit_previewUrl"]').val(previewUrl);
        $('input[name="edit_uploadId"]').val(uploadId);
        $('input[name="edit_duration"]').val(duration);
        $('input[name="edit_thumb"]').val(thumb);
        $('input[name="edit_thumb2"]').val(thumb2);
        $('input[name="edit_free"]').val(free);
        $('input[name="edit_sortCategory"]').val(sortCategory);
        $('input[name="edit_sortPopular"]').val(sortPopular);
        $('input[name="edit_view"]').val(view);
        $('input[name="edit_share"]').val(share);
        $('input[name="edit_like"]').val(like);
        $('input[name="edit_publishDate"]').val(publishDate);

        $('.form-edit-video .thumb').append('<option data-img-src="'+ thumb +'" class="img-1" value="1" selected>img 1</option>');
        $('.video__thumb .thumb').imagepicker();

        //add title hashtag
        $('#edit_video .bootstrap-tagsinput .badge').each(function( index ) {
            let id = $(this).attr('data-id');
            if (!$('.playlist__videos').find('.video[data-id='+id+']').length){
                $(this).clone().appendTo('.playlist__videos');
            }
        });

        $('#edit_video').modal('show');
    });

    // Reject video
    $('#form-reject-video').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                $('.form-btn-reject-video').removeAttr('disabled', 'disabled');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                $('.form-btn-reject-video').removeAttr('disabled', 'disabled');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                $('.form-btn-reject-video').removeAttr('disabled', 'disabled');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $(document).on('click', '.form-btn-reject-video', function (e){
        e.preventDefault();
        if($('#form-reject-video').valid() == true) {
            $('.footer .overlay').addClass('overlaing');
            $('.form-btn-reject-video').attr('disabled', 'disabled');
            $('#form-reject-video').submit();
        }
    });
    // Click button reject video
    $(document).on('click', '.btn-reject-video', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');

        $('#reject_video input[name="id_video_reject"]').val(id);

        $('#reject_video').modal('show');
    });

    // Click show video
    $(document).on('click', '.btn-show-video', function (e) {
        e.preventDefault();
        $('#show_video .modal-title').html('');
        $('#show_video embed').attr('src', '');

        var title = $(this).attr('data-title');
        var url = $(this).attr('data-originalUrl');

        $('#show_video .modal-title').html(title);
        $('#show_video embed').attr('src', url);

        $('#show_video').modal('show');
    });
        // Click dismiss modal pause video
    $(document).on('click', '#show_video .close', function (e) {
        e.preventDefault();
        $('#show_video .modal-title').html('');
        $('#show_video .modal-body div embed').remove();
        $('#show_video .modal-body div').html('<embed src="" style="width: 100%;height: 249px;"/>');
    });
    $(document).on('click', '#show_video .modal-footer button', function (e) {
        e.preventDefault();
        $('#show_video .modal-title').html('');
        $('#show_video .modal-body div embed').remove();
        $('#show_video .modal-body div').html('<embed src="" style="width: 100%;height: 249px;"/>');
    });

    //Check modal #show_video, #upload_show_video có tồn tại class 'show' không.
    $('body').click(function(){
        if(!($( "#show_video" ).hasClass( "show" )))
        {
            $('#show_video .modal-body div embed').remove();
            $('#show_video .modal-body div').html('<embed src="" style="width: 100%;height: 249px;"/>');
        }
        if(!($( "#upload_show_video" ).hasClass( "show" )))
        {
            $('#upload_show_video .modal-body div video').remove();
            $('#upload_show_video .modal-body div').html('<video class="w-100" style="max-height: 300px;" controls src=""></video>');
            $('#upload_show_video').modal('hide');
        }
        if(!($( "#hls_show_video" ).hasClass( "show" )))
        {
            $('#hls_show_video .modal-body').html('');
        }
    });

    // Click show video after upload video
    $(document).on('click', '.btn-upload-show-video', function (e) {
        e.preventDefault();
        $('#upload_show_video video').attr('src', '');

        var url = $(this).attr('data-originalUrl');

        $('#upload_show_video video').attr('src', url);

        $('#upload_show_video').modal('show');
    });
        // Click dismiss modal pause video
    $(document).on('click', '#upload_show_video .close', function (e) {
        e.preventDefault();
        $('#upload_show_video video').attr('src', '');
        $('#upload_show_video .modal-body div video').remove();
        $('#upload_show_video .modal-body div').html('<video class="w-100" style="max-height: 300px;" controls src=""></video>');
        $('#upload_show_video').modal('hide');
    });
    $(document).on('click', '#upload_show_video .modal-footer button', function (e) {
        e.preventDefault();
        $('#upload_show_video video').attr('src', '');
        $('#upload_show_video .modal-body div video').remove();
        $('#upload_show_video .modal-body div').html('<video class="w-100" style="max-height: 300px;" controls src=""></video>');
        $('#upload_show_video').modal('hide');
    });

    // Suggest Hidden video (Đề nghị ẩn)
    $(document).on('click', '.suggest-hide-video', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Suggest hide video',
            text: 'Bạn muốn đề nghị ẩn video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/suggest-hide',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // CP đăng ký lại
    $(document).on('click', '.re-register-video', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Re-Register video',
            text: 'Bạn muốn đăng ký lại video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/re-register',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });

    // Hidden video
    $(document).on('click', '.hidden-video', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Hidden video',
            text: 'Bạn muốn ẩn video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/hidden',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Show video
    $(document).on('click', '.show-video', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Show video',
            text: 'Bạn muốn hiện video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/show',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });

    // Free video
    $(document).on('click', '.free-video', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Free video',
            text: 'Bạn muốn thay đổi trạng thái video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/free',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });

    // Approve checkbox video
    $(document).on('click', '.approve-video', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Aprove video',
            text: 'Bạn muốn phê duyệt video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/approve',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Approve checkbox video
    $(document).on('change', '#act-checkbox-video', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let checkbox = [];
        $('input[name="checkbox_video[]"]:checked').each(function(){
            checkbox.push($(this).val());
        });

        if (checkbox.length)
        {
            Swal.fire({
                title: 'Set new',
                text: 'Bạn muốn set new các video?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Thoát',
            }).then((result) => {
                if (result.value) {
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });
                    $.ajax(BASE_URL + '/video-manager/act-checkbox',{
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            ids: checkbox,
                        },
                        success:function (data){
                            console.log(data);
                            if (data.error){
                                Swal.fire({
                                    type: 'error',
                                    title: 'Error',
                                    text: data.error,
                                })
                            }
                            else if (data.success) {
                                Swal.fire(
                                    'Thành công!',
                                    data.success,
                                    'success'
                                ).then((value) => {
                                    $(location).attr('href', href);
                                });
                            }
                            else {
                                $('.footer .overlay').removeClass('overlaing');
                                Swal.fire(
                                    'Error!',
                                    data.logged,
                                    'error'
                                ).then((value) => {
                                    $(location).attr('href', BASE_URL);
                                });
                            }
                        }
                    });
                }
            });
        }else{
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy chọn video!',
            });
        }
        $('select[name="act-checkbox-video"]').selectpicker('val', '');
    });
    // Set New video
    $(document).on('click', '.btn-setNew-video', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let id = $(this).attr('data-id');

        Swal.fire({
            title: 'Set new',
            text: 'Bạn muốn set new video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/set-new',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Delete video
    $(document).on('click', '.btn-delete-video', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let id = $(this).attr('data-id');

        Swal.fire({
            title: 'Xóa video',
            text: 'Bạn muốn xóa video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });

    // Click button action history video
    $(document).on('click', '.btn-action-history-video', function (e) {
        e.preventDefault();
        $('#action_history_video-title').html('');
        $('.list-action-video-history tbody').html('');

        let title = $(this).attr('data-title');
        let history = $(this).attr('data-history');
        let his = JSON.parse(history);
        //Đảo ngược mảng
        his.reverse();
        let html = '';
        $.each( his, function( key, value ) {
            if (value['action'] ==  1){
                var action = 'Duyệt';
            }
            else if (value['action'] ==  2){
                var action = 'Hủy live';
            }
            else if (value['action'] == 3){
                var action = 'Dừng live';
            }
            else if (value['action'] == 4){
                var action = 'Chuyển live thành video';
            }
            else if (value['action'] == 5){
                var action = 'Lên lịch';
            }
            else if (value['action'] == 6){
                var action = 'Cập nhật';
            }
            else if (value['action'] == 7){
                var action = 'Ẩn';
            }
            else if (value['action'] == 8){
                var action = 'Từ chối duyệt';
            }
            else if (value['action'] == 9){
                var action = 'Yêu cầu ẩn kênh';
            }
            else{
                var action = '';
            }
            let date = new Date(value['actiontime']);
            date = date.toLocaleString();
            html += '<tr><td>'+ value['username'] +'</td><td align="center">'+ action +'</td><td align="center">'+ date +'</td></tr>';
        });

        $('#action_history_video-title').html(title);
        $('.list-action-video-history tbody').html(html);

        $('#action_history_video').modal('show');
    });

    $('.form-btn-upload-test').click(function (e){
        e.preventDefault();
        if($('.upload__form--test').valid() == true) {
            $('.footer .overlay').addClass('overlaing');
            $('.upload__form--test').submit();
        }
    });
    $('.upload__form--test').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'success',
                    title: 'Success',
                    text: res.success,
                });
                $('.detail-response').html(res.data);
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });

    // Upload video JS thuần sử dụng axios
    const CancelToken = axios.CancelToken;
    let cancelVideo;
    $(document).on('change', '.box .upload-video-step-1 .video_file',function () {
        $('.footer .overlay').addClass('overlaing');
        let $this = $(this);
        $('.btn-upload-show-video').attr('data-originalUrl', '');
        $('.btn-upload-show-video').css('display', 'none');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/video-manager/getToken',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                    // $('#addnew_video').modal('hide');
                }
                else if(res.success) {

                    let token = res.success;
                    $('.box .upload-video-step-1').hide();
                    $('.box .upload-video-step-2').show();
                    // $('.box .upload-video-step-2 .thumb').append('<option data-img-src="'+ BASE_URL +'/images/loader.gif" class="img-1" value="1" selected>img 1</option>');
                    // $('.box .upload-video-step-2 .thumb').append('<option data-img-src="'+ BASE_URL +'/images/loader.gif" class="img-2" value="2">img 2</option>');
                    $('.video__thumb .thumb').imagepicker();
                    $('.box .image-picker.thumb option').attr('data-img-src', BASE_URL +'/images/loader.gif');
                    $('.box .image-select .thumbnails img').attr('src', BASE_URL +'/images/loader.gif');
                    $('.box .upload-video-step-2 .progress .progress-bar').css('width','0%');
                    console.log($(".box .upload-video-step-1 .video_file")[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $(".box .upload-video-step-1 .video_file")[0].files[0]);
                    axios.post( res.api_upload,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'token': token
                            },
                            onUploadProgress: function(progressEvent) {
                                let percentCompletedCSS = (progressEvent.loaded * 100) / progressEvent.total;
                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                // console.log(percentCompletedCSS);
                                $this.parents('.box').find('.progress-bar').css('width',percentCompletedCSS+'%');
                                $this.parents('.box').find('.progress-bar').html('<div class="progress-status">' + percentCompleted +' %</div>');
                                if (percentCompleted == 100){
                                    $this.parents('.box').find('.progress-bar').css('width',percentCompleted+'%');
                                    $this.parents('.box').find('.progress-bar').html('<div class="progress-status">' + 'Đang xử lý' +'</div>');
                                }
                            },
                            cancelToken: new CancelToken(function executor(c) {
                                // An executor function receives a cancel function as a parameter
                                cancelVideo = c;
                                return false;
                            })
                        }
                    ).then(function(data){
                        $('.footer .overlay').removeClass('overlaing');
                        console.log('SUCCESS!!');
                        console.log(data);
                        let item = data.data;
                        if (item.code == 200){
                            $('.box .upload-video-step-2 .progress .progress-bar').css('width','100%');
                            $('.box .upload-video-step-2 .progress .progress-bar').text('Done!');
                            $('.box .upload-video-step-2 .thumb').empty();
                            $('.box .upload-video-step-2 .yc-btn--sm').addClass('upload-done');
                            $('.box .upload-video-step-2 input[name=originUrl]').val(item.data.originUrl);
                            $('.box .upload-video-step-2 input[name=token_trans]').val(token);
                            $('.box .upload-video-step-2 input[name=previewUrl]').val(item.data.previewUrl);
                            $('.box .upload-video-step-2 input[name=duration]').val(item.data.duration);
                            $('.box .upload-video-step-2 input[name=fileName]').val(item.data.fileName);
                            $('.box .upload-video-step-2 input[name=uploadId]').val(item.data.uploadId);
                            $('.box .upload-video-step-2 input[name=thumbs1]').val(item.data.thumbs2[0]);
                            $('.box .upload-video-step-2 input[name=thumbs2]').val(item.data.thumbs1[0]);
                            $('.box .upload-video-step-2 .thumb').append('<option data-img-src="'+ item.data.thumbs2[0] +'" class="img-1" value="1" selected>img 1</option>');
                            $('.box .upload-video-step-2 .thumb').append('<option data-img-src="'+ item.data.thumbs1[0] +'" class="img-2" value="2">img 2</option>');
                            $('.video__thumb .thumb').imagepicker();
                            $('.btn-upload-show-video').attr('data-originalUrl', item.data.originUrl);
                            $('.btn-upload-show-video').css('display', 'block');
                        }else {
                            $('.box .upload-video-step-1').show();
                            $('.box .upload-video-step-2').hide();
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $('.box .upload-video-step-1').show();
                        $('.box .upload-video-step-2').hide();
                        let hClass = $this.hasClass('cancel');
                        if(!hClass){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: 'Lỗi kết lối server!',
                            });
                            console.log('FAILURE!!');
                        }
                    });
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        res.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
    // Sort video
    $(document).on('click', '.btn-sort-video', function (e) {
        e.preventDefault();
        let sort = $(this).attr("data-sort");
        let limit = $(this).attr("data-limit");
        let page = $(this).attr("data-page");
        let url = window.location.href.slice(window.location.href.indexOf('?') + 1);
        console.log('url: ' + url);

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/video-manager/sort',{
            type: 'get',
            dataType: 'json',
            data: {
                sort: sort,
                url: url,
                limit: limit,
                page: page
            },
            success:function (data){
                console.log(data);
                $('.footer .overlay').removeClass('overlaing');
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else if(data.success) {
                    if (data.html != '')
                    {
                        $('table tbody').html(data.html);
                        $('.btn-sort-video').attr('data-page', data.page);
                    }
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });

    // Delete file video uploaded when load
    window.onbeforeunload = function(){
        let hasClass = document.getElementsByClassName("unload--delVideo");
        if(hasClass){
            let uploadId = $('.box.unload--delVideo input[name="uploadId"]').val();
            let uploadClone = $('.boxClone.unload--delVideo input[name="uploadId"]').val();
            if (uploadId && uploadId.length > 0){
                console.log(uploadId);

                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/delete-file',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        uploadId: uploadId
                    },
                    success:function (data){
                        console.log(data);
                        $('.footer .overlay').removeClass('overlaing');
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
            if (uploadClone && uploadClone.length > 0){
                console.log(uploadId);

                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/delete-file',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        uploadId: uploadClone
                    },
                    success:function (data){
                        console.log(data);
                        $('.footer .overlay').removeClass('overlaing');
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }

        }
    };

    // Click submit form Clone content video
    $('.boxClone .btn-clone-content-video').click(function (e){
        e.preventDefault();
        if($('.boxClone .form-clone-video').valid() == true) {
            $('.btn-upload-show-video').attr('data-originalUrl', '');
            $('.btn-upload-show-video').css('display', 'none');
            $('.footer .overlay-group-dot-circle').addClass('overlaing');
            $('.boxClone .form-clone-video').submit();
        }
    });
    $('.boxClone .btn-create-content-video').click(function (e){
        e.preventDefault();
        let fileName = $('.boxClone .upload-video-step-2 input[name=fileName]').val();
        let hashtag = $('.boxClone .upload-video-step-2 input[name=create_hashtag_video]').val();
        let hash = hashtag.split(",");
        if (fileName.length > 0)
        {
            if (hash.length >= 5)
            {
                if($('.boxClone .form-create-video').valid() == true) {
                    $('.boxClone .btn-create-content-video').attr('disabled', 'disabled');
                    $('.boxClone .form-create-video').submit();
                }
            }else{
                Swal.fire({
                    type: 'warning',
                    title: 'Warning',
                    text: 'Hãy nhập 5 Hashtag trở lên!',
                });
            }
        }else{
            Swal.fire({
                type: 'warning',
                title: 'Warning',
                text: 'Chưa có file name.',
            });
        }
    });
    //Form ajax Clone video
    $('.form-clone-video').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.boxClone .upload-video-step-1').show();
                $('.boxClone .upload-video-step-2').hide();
                $('.footer .overlay-group-dot-circle').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.boxClone .upload-video-step-1').hide();
                $('.boxClone .upload-video-step-2').show();
                let hashtag = '';
                if (res.data.hastags && res.data.hastags != []){
                    hashtag += (res.data.hastags).join(',');
                }
                $('.boxClone .upload-video-step-2 .thumb').empty();
                $('.boxClone .upload-video-step-2 input[name=create_title_video]').val(res.data.title);
                $('.boxClone .upload-video-step-2 textarea[name=create_des_video]').val(res.data.description);
                $('.boxClone .upload-video-step-2 input[name=create_hashtag_video]').tagsinput('add', hashtag);
                $('.boxClone .upload-video-step-2 input[name=originUrl]').val(res.data.originUrl);
                $('.boxClone .upload-video-step-2 input[name=previewUrl]').val(res.data.previewUrl);
                $('.boxClone .upload-video-step-2 input[name=duration]').val(res.data.duration);
                $('.boxClone .upload-video-step-2 input[name=uploadId]').val(res.data.uploadId);
                $('.boxClone .upload-video-step-2 input[name=fileName]').val(res.data.fileName);
                $('.boxClone .upload-video-step-2 input[name=thumbs1]').val(res.data.thumbs2[0]);
                $('.boxClone .upload-video-step-2 input[name=thumbs2]').val(res.data.thumbs1[0]);
                $('.boxClone .upload-video-step-2 .thumb').append('<option data-img-src="'+ res.data.thumbs2[0] +'" class="img-1" value="1" selected>img 1</option>');
                $('.boxClone .upload-video-step-2 .thumb').append('<option data-img-src="'+ res.data.thumbs1[0] +'" class="img-2" value="2">img 2</option>');
                $('.boxClone .video__thumb .thumb').imagepicker();
                $('.boxClone .btn-upload-show-video').attr('data-originalUrl', res.data.originUrl);
                $('.boxClone .btn-upload-show-video').css('display', 'block');

                $('.footer .overlay-group-dot-circle').removeClass('overlaing');
            }else {
                $('.boxClone .upload-video-step-1').show();
                $('.boxClone .upload-video-step-2').hide();
                $('.footer .overlay-group-dot-circle').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $('.boxClone .form-create-video').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            $('.boxClone .btn-create-content-video').removeAttr('disabled');
            if (res.error){
                $('.boxClone .btn-create-content-video').removeAttr('disabled');
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.boxClone .upload-video-step-2 input[name="uploadId"]').remove();
                $('.boxClone .btn-create-content-video').removeAttr('disabled');
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/video-manager');
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $('.boxClone .video-thumb-upload').dropify({
        messages: {
            'default': 'Hình ảnh thu nhỏ tùy chỉnh',
            'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
            'remove':  'Hủy',
            'error':   'Xin lỗi có lỗi đã xảy ra'
        },
        error: {
            'fileSize': 'Ảnh quá lớn ({{ value }} max).',
            'minWidth': 'The image width is too small ({{ value }}}px).',
            'maxWidth': 'The image width is too big ({{ value }}}px).',
            'minHeight': 'The image height is too small ({{ value }}}px).',
            'maxHeight': 'The image height is too big ({{ value }}px).',
            'imageFormat': 'Định dạng không đúng ({{ value }}).',
            'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
        },
    });
    // Search video account CP (Clone video)
    $(document).on('change', '#clone_video_list_cp', function (e) {
        e.preventDefault();
        $('.clone-video-account-cp div').remove();
        $('#clone_video_create_channel_cp option').remove();
        var $this = $(this);
        var id = $('#clone_video_list_cp').val();
        console.log(id);
        $('.clone-video-account-cp').html('<select name="staffId_acc_cp" id="clone_video_list_acc_cp" required data-msg="Chưa chọn tài khoản" class="form-control" data-live-search="true" title="Chọn tài khoản CP"></select>');

        //AJAX get info CP
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/video-manager/search-info-cp',{
            type: 'POST',
            dataType: 'json',
            data: {
                id: id,
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    $('#clone_video_list_acc_cp').removeAttr('disabled');
                    $('#clone_video_list_acc_cp').attr('title', data.error);
                    $('#clone_video_list_acc_cp').selectpicker();
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else if(data.success) {
                    $('#clone_video_list_acc_cp').attr('title', 'Chọn tài khoản CP');
                    $('#clone_video_list_acc_cp').removeAttr('disabled');
                    $('#clone_video_list_acc_cp').html(data.success.account);
                    $('#clone_video_list_acc_cp').selectpicker();
                    $('#clone_video_create_channel_cp').html(data.success.channel);
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });

    // Set Comment
    $(document).on('click', '.comments-video', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let id = $(this).attr('data-id');
        let num = $(this).attr('data-num');
        let text = '';
        if (num == 1){
            text += 'Bạn muốn ẩn bình luận video?';
        }else{
            text += 'Bạn muốn hiện bình luận video?';
        }

        Swal.fire({
            title: 'Comment video',
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/comment',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Click button Categories video
    $(document).on('click', '.btn-show-cate', function (e) {
        e.preventDefault();
        $('#show_categories_video-title').html('');
        $('.list-category-movie tbody').html('');

        let cate = $(this).attr('data-cate');
        let title = $(this).attr('data-title');
        let categories = '';
        let html = '';
        if (cate.length > 0)
        {
            categories = JSON.parse(cate);
            $.each( categories, function( key, value ) {
                html += '<tr><td align="center">'+ (key + 1) +'</td><td>'+ value['name'] +'</td></tr>';
            });
        }

        $('#show_categories_video-title').html(title);
        $('.list-category-movie tbody').html(html);

        $('#show_categories_video').modal('show');
    });
    //Click Copy
    $(document).on('click', '.copy-video', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let link = document.getElementById(id);
        link.type = 'text';
        link.select();
        link.setSelectionRange(0, 99999);
        document.execCommand("copy");
        link.type = 'hidden';
    });

    // Checkbox package
    $(document).on('click', '.contentPackageBasic', function (e) {
        let standard = $('.contentPackageStandard').is(':checked');
        if (standard){
            $('.contentPackageStandard').prop("checked", false);
        }
    });
    $(document).on('click', '.contentPackageStandard', function (e) {
        let basic = $('.contentPackageBasic').is(':checked');
        if (basic){
            $('.contentPackageBasic').prop("checked", false);
        }
    });
    $(document).on('click', '.editContentPackageBasic', function (e) {
        let standard = $('.editContentPackageStandard').is(':checked');
        if (standard){
            $('.editContentPackageStandard').prop("checked", false);
        }
    });
    $(document).on('click', '.editContentPackageStandard', function (e) {
        let basic = $('.editContentPackageBasic').is(':checked');
        if (basic){
            $('.editContentPackageBasic').prop("checked", false);
        }
    });


    // Get link play, show link movie
    $(document).on('click', '.btn-get-link-play', function (e) {
        e.preventDefault();
        $('.footer .overlay').addClass('overlaing');
        $('#hls_show_video .modal-body').html('');
        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        console.log(id);
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/video-manager/link-play',{
            type: 'GET',
            dataType: 'json',
            data: {
                id: id
            },
            success: async function (data){
                console.log(data);
                $('.footer .overlay').removeClass('overlaing');
                if (data.error){
                    console.log('error');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else if(data.null) {
                    Swal.fire({
                        type: 'warning',
                        title: 'Warning',
                        text: data.null,
                    });
                }
                else if(data.success) {
                    $('#hls_show_video .modal-title').html(title);
                    let str = data.success;
                    if (str.includes('m3u8')){
                        $('#hls_show_video .modal-body').html('<video id="video" controls class="w-100"></video>');
                        let video = document.getElementById('video');
                        if (Hls.isSupported()) {
                            let hls = new Hls();
                            hls.loadSource(str);
                            hls.attachMedia(video);
                            hls.on(Hls.Events.MANIFEST_PARSED, function() {
                                video.play();
                            });
                        }
                    }else{
                        $('#hls_show_video .modal-body').html('<video controls autoplay class="w-100" src="'+ str +'"></video>');
                    }

                    $('#hls_show_video').modal('show');
                }
                else {
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
    // Click dismiss modal pause video
    $(document).on('click', '#hls_show_video .close', function (e) {
        e.preventDefault();
        $('#hls_show_video .modal-title').html('');
        $('#hls_show_video .modal-body').html('');
    });
    $(document).on('click', '#hls_show_video .modal-footer button', function (e) {
        e.preventDefault();
        $('#hls_show_video .modal-title').html('');
        $('#hls_show_video .modal-body').html('');
    });

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
        console.log('Android');
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        console.log('iPhone');
        // setTimeout(function () {
        //     window.location = "https://play.google.com/store/apps/details?id=com.dtsgaming.spacehero.shooter";
        // }, 1000);
        // window.location = "meclip://meclip.vn/lauch-android";
    }

})(jQuery);
