(function ($) {
    // Create banner
    $(document).ready(function () {
        $('#form-create-faq').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                var href = window.location.href;
                $('#btn-create-faq').removeAttr('disabled');
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', href);
                    });
                }
            }
        });
        $('#btn-create-faq').click(function (e){
            e.preventDefault();
            if($('#form-create-faq').valid() == true) {
                $('#btn-create-faq').attr('disabled', 'disabled');
                $('#form-create-faq').submit();
            }
        });
    });

    // Edit banner
    $(document).ready(function () {
        var href = window.location.href;
        $('#form-edit-faq').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                $('#btn-edit-faq').removeAttr('disabled', 'disabled');
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', href);
                    });
                }
            }
        });
        $('#form-btn-edit-faq').click(function (e){
            e.preventDefault();
            if($('#form-edit-faq').valid() == true){
                $('#form-btn-edit-faq').attr('disabled', 'disabled');
                $('#form-edit-faq').submit();
            }

        });
    });
    // Click button edit
    $(document).on('click', '.btn-edit-faq', function (e) {
        e.preventDefault();

        var id = $(this).attr('data-id');
        var title = $(this).attr('data-title');
        var content = $(this).attr('data-content');
        var order = $(this).attr('data-order');
        var status = $(this).attr('data-status');

        $('input[name="id_faq"]').val(id);
        $('input[name="edit_title"]').val(title);
        $('textarea[name="edit_content"]').html(content);
        $('#edit_faq .note-editable').html(content);
        $('input[name="edit_order"]').val(order);
        $('select[name="edit_status"]').val(status);

        $('#edit_faq').modal('show');
    });
    // Click button detail FAQ
    $(document).on('click', '.btn-detail-faq', function (e) {
        e.preventDefault();

        var title = $(this).attr('data-title');
        var content = $(this).attr('data-content');
        var order = $(this).attr('data-order');
        var status = $(this).attr('data-status');

        if (status == 0){
            var str = 'Active';
        }else{
            var str = 'Đã Ẩn';
        }

        $('#detail-title').html(title);
        $('#detail-status').html(str);
        $('#detail-content').html(content);
        $('#detail-order').html(order);

        $('#detail_faq').modal('show');
    });
    // Click btn xóa FAQ
    $(document).on('click', '.btn-delete-faq', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete FAQ',
            text: 'Bạn muốn xóa câu hỏi này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/faq/delete',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Click btn xóa Help
    $(document).on('click', '.btn-delete-help', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Xóa trợ giúp',
            text: 'Bạn muốn xóa trợ giúp này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Từ chối',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/help/delete',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });

})(jQuery);
