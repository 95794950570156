(function ($) {
    // Lock account system
    $(document).on('click', '.delete-cp', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let id = $(this).attr('data-id');

        Swal.fire({
            title: 'Xóa đối tác?',
            text: 'Bạn muốn xóa đối tác này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/delete_cp',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        user_id: id
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else if (data.logged){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.logged,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Lock account system
    $(document).on('click', '.lock-cp', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Lock account CP?',
            text: 'Bạn muốn khóa tất cả tài khoản của đối tác này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/lock_cp',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        user_id: id
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else if (data.logged){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.logged,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // unLock account system
    $(document).on('click', '.active-cp', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'unLock account CP?',
            text: 'Bạn muốn mở khóa tất cả tài khoản của đối tác này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/unlock_cp',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        user_id: id
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else if (data.logged){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.logged,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
})(jQuery);
