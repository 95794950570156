(function ($) {
    // Create banner
    $(document).ready(function () {
        $('#form-create-banner').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                $('#btn-create-banner').removeAttr('disabled');
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL + '/banner-config');
                    });
                }
            }
        });
        $('#btn-create-banner').click(function (e){
            e.preventDefault();
            if($('#form-create-banner').valid() == true) {
                $('#btn-create-banner').attr('disabled', 'disabled');
                $('.footer .overlay').addClass('overlaing');
                $('#form-create-banner').submit();
            }
        });
    });
    // Click input upload avatar create
    $('#btn-upload-img-create-banner').click(function (e) {
        e.preventDefault();
        $('#img_banner').click();
    });
    // Show Avatar create
    $('#img_banner').change(function (e) {
        e.preventDefault();
        let output = document.getElementById('show-img-banner');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-create-banner').html(e.target.files[0].name);
        $(this).parents('.banner__img .form-group').find('label').remove();
        $('#show-img-banner').css('display', 'block');
    });
    // Click input upload avatar create
    $('#btn-upload-gif-create-banner').click(function (e) {
        e.preventDefault();
        $('#gif_banner').click();
    });
    // Show Avatar create
    $('#gif_banner').change(function (e) {
        e.preventDefault();
        let output = document.getElementById('show-gif-banner');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-gif-create-banner').html(e.target.files[0].name);
        $(this).parents('.banner__gif .form-group').find('label').remove();
        $('#show-gif-banner').css('display', 'block');
    });
    // Change Type Banner
    $('#type_banner').change(function (e) {
        e.preventDefault();
        let type = $(this).val();
        if (type == 2)
        {
            $('#addnew_banner .banner__img').css('display', 'none');
            $('#addnew_banner .banner__gif').css('display', 'block');
            $('#img_banner').attr('disabled', 'disabled');
            $('#gif_banner').removeAttr('disabled');
        }else{
            $('#addnew_banner .banner__img').css('display', 'block');
            $('#addnew_banner .banner__gif').css('display', 'none');
            $('#gif_banner').attr('disabled', 'disabled');
            $('#img_banner').removeAttr('disabled');
        }
    });
    // Change edit Type Banner
    $('#edit_type_banner').change(function (e) {
        e.preventDefault();
        let type = $(this).val();
        if (type == 2)
        {
            $('#edit_banner .banner__img').css('display', 'none');
            $('#edit_banner .banner__gif').css('display', 'block');
            $('#edit_img_banner').attr('disabled', 'disabled');
            $('#edit_gif_banner').removeAttr('disabled');
        }else{
            $('#edit_banner .banner__img').css('display', 'block');
            $('#edit_banner .banner__gif').css('display', 'none');
            $('#edit_gif_banner').attr('disabled', 'disabled');
            $('#edit_img_banner').removeAttr('disabled');
        }
    });

    // Edite banner
    $(document).ready(function () {
        var href = window.location.href;
        $('#form-edit-banner').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                $('#btn-edit-banner').removeAttr('disabled', 'disabled');
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', href);
                    });
                }
            }
        });
        $('#btn-edit-banner').click(function (e){
            e.preventDefault();
            if($('#form-edit-banner').valid() == true){
                $('#btn-edit-banner').attr('disabled', 'disabled');
                $('.footer .overlay').addClass('overlaing');
                $('#form-edit-banner').submit();
            }

        });
    });
    // Click button edit
    $(document).on('click', '.tbl-edit-banner', function (e) {
        e.preventDefault();

        let id = $(this).attr('data-id');
        let name = $(this).attr('data-name');
        let link = $(this).attr('data-link');
        let url = $(this).attr('data-url');
        let sort = $(this).attr('data-sort');
        let type = $(this).attr('data-type');
        if (url.length > 0 && url != '' && url != null && url != 'null')
        {
            var str = url.split(".");
            if(str[str.length - 1] == 'gif')
            {
                $('select[name="edit_type"]').val(2);
                $('#edit_banner .banner__img').css('display', 'none');
                $('#edit_banner .banner__gif').css('display', 'block');
                $('#edit_img_banner').attr('disabled', 'disabled');
                $('#edit_gif_banner').removeAttr('disabled');
                var output_a = document.getElementById('edit-show-gif-banner');
                output_a.src = url;
                $('#edit-show-gif-banner').css('display', 'block');
            }else{
                $('select[name="edit_type"]').val(1);
                $('#edit_banner .banner__img').css('display', 'block');
                $('#edit_banner .banner__gif').css('display', 'none');
                $('#edit_gif_banner').attr('disabled', 'disabled');
                $('#edit_img_banner').removeAttr('disabled');
                var output_a = document.getElementById('edit-show-img-banner');
                output_a.src = url;
                $('#edit-show-img-banner').css('display', 'block');
            }
        }

        $('input[name="id_banner"]').val(id);
        $('input[name="edit_name_banner"]').val(name);
        $('input[name="edit_link_banner"]').val(link);
        $('select[name="edit_typeLocation"]').val(type);
        $('input[name="edit_sort"]').val(sort);
        $('input[name="old_image"]').val(url);

        // var output_a = document.getElementById('edit-show-img-banner');
        // output_a.src = url;
        // $('#edit-show-img-banner').css('display', 'block');


        $('#edit_banner').modal('show');
    });
    // Click input upload image edit
    $('#btn-upload-img-edit-banner').click(function (e) {
        e.preventDefault();
        $('#edit_img_banner').click();
    });
    // Show Imge edit
    $('#edit_img_banner').change(function (e) {
        e.preventDefault();
        var output = document.getElementById('edit-show-img-banner');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-edit-banner').html(e.target.files[0].name);
        $(this).parents('.banner__img .form-group').find('label').remove();
        $('#edit-show-img-banner').css('display', 'block');
    });
    // Click input upload image edit
    $('#btn-upload-gif-edit-banner').click(function (e) {
        e.preventDefault();
        $('#edit_gif_banner').click();
    });
    // Show Imge edit
    $('#edit_gif_banner').change(function (e) {
        e.preventDefault();
        var output = document.getElementById('edit-show-gif-banner');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-gif-edit-banner').html(e.target.files[0].name);
        $(this).parents('.banner__gif .form-group').find('label').remove();
        $('#edit-show-gif-banner').css('display', 'block');
    });


    // Delete banner
    $(document).on('click', '.delete-banner', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete banner',
            text: 'Bạn muốn xóa banner?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/banner-config/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Hidden banner
    $(document).on('click', '.hidden-banner', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Hidden banner',
            text: 'Bạn muốn ẩn banner?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/banner-config/hidden',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Show banner
    $(document).on('click', '.show-banner', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Show banner',
            text: 'Bạn muốn hiện banner?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/banner-config/show',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });

    // Change select Location banner
    $(document).on('click', '#typeLocation', function (e) {
        e.preventDefault();
        let location = $(this).val();
        console.log(location);
        if (location == 1){
            $('.sizeBanner').html('(1546x423)');
        }else{
            $('.sizeBanner').html('(1320x300)');
        }
    });
    $(document).on('click', '#editTypeLocation', function (e) {
        e.preventDefault();
        let location = $(this).val();
        console.log(location);
        if (location == 1){
            $('.editSizeBanner').html('(1546x423)');
        }else{
            $('.editSizeBanner').html('(1320x300)');
        }
    });

})(jQuery);
