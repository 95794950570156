/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// CommonJS
require('./bootstrap');
require('bootstrap-select/dist/js/bootstrap-select.min');
require('jquery/dist/jquery.min');
require('./validate');
require('./custom');
require('./dropify');
require('./login');
require('./account_manager');
require('./account_user');
require('./role');
require('./kind');
require('./chanel');
require('./video');
require('./playlist');
require('./suggest');
require('./general_view');
require('./channel_config');
require('./popular');
require('./hashtag');
require('./banner');
require('./advertise');
require('./faq');
require('./changepass');
require('./modal');
require('./datetimepicker');
require('./summernote');
require('./config_kind');
require('./report');
require('./list_cp');
require('./cskh');
require('./tagsinput');
require('./comments');
require('./time_off');
require('./alert');
require('./supper-app');
require('./black-word');
require('./config');
require('./notification');
