(function ($) {
    // Create Account
    $('#create_account').click(function (e) {
        e.preventDefault();
        // Check Username
        if($('#create_username').val().length == 0)
        {
            $('.error-create-usernme').text('Bắt buộc nhập!');
        }
        else
        {
            $('.error-create-usernme').text('');
        }
        if(/^[a-zA-Z0-9_]*$/.test($('#create_username').val()) == false) {
            $('.error-create-usernme').text('Không chứa các ký tự đặng biệt!');
        }
        // Check Tên hiển thị
        if($('#create_displayName').val().length == 0)
        {
            $('.error-create-displayName').text('Bắt buộc nhập!');
        }
        else
        {
            $('.error-create-displayName').text('');
        }
        // Check Password
        if($('#create_pass').val().length == 0)
        {
            $('.error-create-pass').text('Bắt buộc nhập!');
        }
        else if ($('#create_pass').val().length < 6)
        {
            $('.error-create-pass').text('Password phải chứa ít nhất 6 ký tự');
        }
        else
        {
            $('.error-create-pass').text('');
        }
        // Check rePassword
        if($('#create_re_pass').val().length == 0)
        {
            $('.error-create-re_pass').text('Bắt buộc nhập!');
        }
        else if ($('#create_re_pass').val().length < 6)
        {
            $('.error-create-re_pass').text('Chứa ít nhất 6 ký tự');
        }
        else
        {
            $('.error-create-re_pass').text('');
        }
        // Check Phone
        if($('#create_phone').val().length == 0)
        {
            $('.error-create-phone').text('Bắt buộc nhập.');
        }
        else if($('#create_phone').val().length < 6 || $('#create_phone').val().length > 11)
        {
            $('.error-create-phone').text('Số điện thoại từ 6-12 số!');
        }
        else
        {
            $('.error-create-phone').text('');
        }
        // Check Roles
        if($('#create_roles').val().length == 0)
        {
            $('.error-create-roles').text('Bắt buộc chọn!');
        }
        else
        {
            $('.error-create-roles').text('');
        }
        // Check Pass & rePass
        if($('#create_re_pass').val() != $('#create_pass').val())
        {
            $('.error-create-repass').text('Password & rePassword phải trùng nhau.');
        }
        else
        {
            $('.error-create-repass').text('');
        }

        if($('#create_username').val().length > 0 && $('#create_displayName').val().length > 0
            && $('#create_pass').val().length > 5 && $('#create_re_pass').val().length > 5
            && $('#create_phone').val().length > 5 && $('#create_phone').val().length < 12
            && $('#create_re_pass').val() == $('#create_pass').val() && $('#create_roles').val().length > 0
            && /^[a-zA-Z0-9_]*$/.test($('#create_username').val()) == true)
        {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/account-manager/create',{
                type: 'POST',
                dataType: 'json',
                data: {
                    username: $('#create_username').val(),
                    displayName: $('#create_displayName').val(),
                    password: $('#create_pass').val(),
                    phone: $('#create_phone').val(),
                    roles: $('#create_roles').val(),
                },
                success:function (data){
                    console.log(data);
                    if (data.error){
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: data.error,
                        });
                    }
                    else {
                        Swal.fire(
                            'Thành công!',
                            'Tạo user thành công.',
                            'success'
                        ).then((value) => {
                            $(location).attr('href', BASE_URL + '/account-manager');
                            // window.location= BASE_URL + "/admin/index";
                        });
                    }
                }
            });
        }
    });

    // Search Account
    // $('#account-search').click(function (e) {
    //     e.preventDefault();
    //
    //     if($('input[name="account_search_name"]').val().length > 0 || $('select[name="account_search_role"]').val().length > 0)
    //     {
    //         var search_name = $('input[name="account_search_name"]').val();
    //         $(location).attr('href', BASE_URL + '/account-manager?name=' + search_name);
    //     }
    //     else
    //     {
    //         Swal.fire({
    //             type: 'warning',
    //             text: 'Hãy nhập thông tin để search!',
    //         });
    //     }
    // });
    // Active account
    $(document).on('click', '.active_account', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Active account?',
            text: 'Bạn muốn Active tài khoản này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/active',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        user_id: id
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else if (data.logged){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.logged,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                                // window.location= BASE_URL + "/admin/index";
                            });
                        }
                    }
                });
            }
        });
    });
    // Lock account system
    $(document).on('click', '.lock_account', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Lock account?',
            text: 'Bạn muốn ẩn tài khoản này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/lock',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        user_id: id
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else if (data.logged){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.logged,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                                // window.location= BASE_URL + "/admin/index";
                            });
                        }
                    }
                });
            }
        });
    });
    // unLock account system
    $(document).on('click', '.unlock_account', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'unLock account?',
            text: 'Bạn muốn hiện tài khoản này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/unlock',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        user_id: id
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else if (data.logged){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.logged,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                                // window.location= BASE_URL + "/admin/index";
                            });
                        }
                    }
                });
            }
        });
    });
    // Delete account
    $(document).on('click', '.delete_account', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete account?',
            text: "Bạn muốn Xóa tài khoản này?!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        user_id: id
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                'Xóa thành công.',
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                                // window.location= BASE_URL + "/admin/index";
                            });
                        }
                    }
                });
            }
        });
    });

    // Approve account user
    $(document).on('click', '.approve-user', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Approve user',
            text: 'Bạn muốn duyệt user?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/user/approve',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Turn off Kiểm duyệt
    $(document).on('click', '.turn-off-user', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Turn off approve content user',
            text: 'Bạn muốn tắt kiểm duyệt nội dung user?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/user/off-approve-content',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Turn on Kiểm duyệt
    $(document).on('click', '.turn-on-user', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Turn on approve content user',
            text: 'Bạn muốn bật kiểm duyệt nội dung user?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/user/on-approve-content',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
        // Dừng hợp tác user
    $(document).on('click', '.stop-cooperate-user', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Stop cooperating user',
            text: 'Bạn muốn dừng hợp tác user?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/user/stop-cooperating-user',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Create Account cho đối tác
    $('#create_account_cp').click(function (e) {
        e.preventDefault();
        // Check Username
        if($('#create_username').val().length == 0)
        {
            $('.error-create-usernme').text('Bắt buộc nhập!');
        }
        else
        {
            $('.error-create-usernme').text('');
        }
        // Check Password
        if($('#create_pass').val().length == 0)
        {
            $('.error-create-pass').text('Bắt buộc nhập!');
        }
        else if ($('#create_pass').val().length < 6)
        {
            $('.error-create-pass').text('Password phải chứa ít nhất 6 ký tự');
        }
        else
        {
            $('.error-create-pass').text('');
        }
         // Check Tên hiển thị
        if($('#create_displayName').val().length == 0)
        {
            $('.error-create-displayName').text('Bắt buộc nhập!');
        }
        else
        {
            $('.error-create-displayName').text('');
        }
        // Check rePassword
        if($('#create_re_pass').val().length == 0)
        {
            $('.error-create-re_pass').text('Bắt buộc nhập!');
        }
        else if ($('#create_re_pass').val().length < 6)
        {
            $('.error-create-re_pass').text('Chứa ít nhất 6 ký tự');
        }
        else
        {
            $('.error-create-re_pass').text('');
        }
        // Check Roles
        if($('#create_cps').val().length == 0)
        {
            $('.error-create-cps').text('Bắt buộc chọn!');
        }
        else
        {
            $('.error-create-cps').text('');
        }
        // Check Pass & rePass
        if($('#create_re_pass').val() != $('#create_pass').val())
        {
            $('.error-create-repass').text('Password & rePassword phải trùng nhau.');
        }
        else
        {
            $('.error-create-repass').text('');
        }

        if($('#create_username').val().length > 0
            && $('#create_pass').val().length > 5 && $('#create_re_pass').val().length > 5
            && $('#create_re_pass').val() == $('#create_pass').val() && $('#create_cps').val().length > 0)
        {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/account-manager/create_account_cp',{
                type: 'POST',
                dataType: 'json',
                data: {
                    username: $('#create_username').val(),
                    password: $('#create_pass').val(),
                    displayName: $('#create_displayName').val(),
                    id : $('#create_cps').val()
                },
                success:function (data){
                    console.log(data);
                    if (data.error){
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: data.error,
                        });
                    }
                    else {
                        Swal.fire(
                            'Thành công!',
                            'Tạo user thành công.',
                            'success'
                        ).then((value) => {
                            $(location).attr('href', BASE_URL + '/account-manager/cp');
                            // window.location= BASE_URL + "/admin/index";
                        });
                    }
                }
            });
        }
    });
    // Create  CP
    $('#create_cp').click(function (e){
        e.preventDefault();
        if($('#form-create-cp').valid() == true) {
            $('#create_cp').attr('disabled', 'disabled');
            $('#form-create-cp').submit();
        }
    });
    $('#form-create-cp').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('#create_cp').removeAttr('disabled');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $form.find('.submit').addClass('disable');
                $('#create_cp').removeAttr('disabled');
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/account-manager/cp');
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    //Edit information CP
     $(document).ready(function () {
        var href = window.location.href;
        $('#form-edit-cp').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', href);
                    });
                }

            }
        });
        $('#form-btn-edit-cp').click(function (e){
            e.preventDefault();
            if($('#form-edit-cp').valid() == true){
                // $('#btn-edit-kind').attr('disabled', 'disabled');
                $('#form-edit-cp').submit();
            }

        });
    });

    // Click button edit CP
    $(document).on('click', '.btn-edit-cp', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var name = $(this).attr('data-name');
        var alias = $(this).attr('data-alias');
        var taxCode = $(this).attr('data-taxCode');
        var address = $(this).attr('data-address');
        var person = $(this).attr('data-person');
        var dauMoiKD = $(this).attr('data-dauMoiKD');
        var email = $(this).attr('data-email');
        var mobile = $(this).attr('data-mobile');
        var revenueRate = $(this).attr('data-revenueRate');
        var chargeCode = $(this).attr('data-chargeCode');

        $('input[name="edit_id_cp"]').val(id);
        $('input[name="edit_name_cp"]').val(name);
        $('input[name="edit_name_cp_short"]').val(alias);
        $('input[name="edit_cp_mst"]').val(taxCode);
        $('input[name="edit_cp_address"]').val(address);
        $('input[name="edit_cp_person"]').val(person);
        $('input[name="edit_cp_kd"]').val(dauMoiKD);
        $('input[name="edit_cp_email"]').val(email);
        $('input[name="edit_cp_phone"]').val(mobile);
        $('input[name="edit_cp_revenueRate"]').val(revenueRate);
        $('input[name="edit_chargeCode"]').val(chargeCode);

        $('#edit_CP').modal('show');
    });


     // Click button edit account CP
    $(document).on('click', '.btn-edit-acc-cp', function (e) {
        e.preventDefault();
        $('input[name="edit_pass"]').val('');
        $('input[name="edit_re_pass"]').val('');
        let id = $(this).attr('data-id');
        let name = $(this).attr('data-name');
        let displayname = $(this).attr('data-displayName');

        $('input[name="edit_id_acc_cp"]').val(id);
        $('input[name="edit_username"]').val(name);
       $('input[name="edit_displayname"]').val(displayname);

        $('#edit-acc-cp').modal('show');
    });
    //Edit information account CP
     $(document).ready(function () {
        var href = window.location.href;
        $('#form-edit-acc-cp').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', href);
                    });
                }

            }
        });
        $('#btn-edit-acc-cp').click(function (e){
            e.preventDefault();
            if($('#form-edit-acc-cp').valid() == true){
                // $('#btn-edit-kind').attr('disabled', 'disabled');
                $('#form-edit-acc-cp').submit();
            }

        });
    });
     // Dừng hợp tác với đối tác
    $(document).on('click', '.stop-cooperate-cp', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');
        var $this = $(this);
        Swal.fire({
            title: 'Stop cooperating CP',
            text: 'Bạn muốn dừng hợp tác với đối tác?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/cp/stop-cooperating-cp',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            $this.replaceWith('<a class="btnintbl m-b-5 pa-disabled" title="Hợp tác">Hợp tác</a>');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
     // Hợp tác với đối tác
    $(document).on('click', '.start-cooperate-cp', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');
        var $this = $(this);
        Swal.fire({
            title: 'Start cooperating CP',
            text: 'Bạn muốn hợp tác với đối tác?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/cp/start-cooperating-cp',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            $this.replaceWith('<a class="btnintbl m-b-5 pa-disabled" title="Dừng hợp tác">Dừng hợp tác</a>');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
})(jQuery);
