(function ($) {
    //Add video
    $('.config-add-video-hashtag').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.success){
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/hashtag');
                });

            }
            else{
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
        }
    });
    $(document).on('click', '.config-change-hashtag', function (e) {
        e.preventDefault();
        let hashtag = $(this).attr('data-hashtag');

        $('input[name="hashtag"]').val(hashtag);

        $('#addnew_hashtag').modal('show');
    });
})(jQuery);