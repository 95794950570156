(function ($) {
    $('textarea.summernote').summernote({
        height: 100,
        popover: {
            image: [],
            link: [],
            air: []
        }
    });
})(jQuery);
