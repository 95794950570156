(function ($) {
    $('.datetimepicker').datetimepicker({
        timepicker:false,
        format:'Y/m/d',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        },
    });
    $('.datetimepicker-datetime').datetimepicker({
        format:'Y/m/d H:i',
        // allowTimes: [
        //     '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30',
        //     '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30',
        //     '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30',
        //     '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30',
        //     '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00', '24:30'
        // ],
        defaultTime:'00:00',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        },
        minDate:'-1d'
    });
    $('.datetimepicker-date').datetimepicker({
        timepicker:false,
        format:'Y-m-d',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        }
    });
    $('.datetimepicker-time').datetimepicker({
        datepicker:false,
        format:'H:i'
    });
})(jQuery);
