(function ($) {
    // Create Chanel
    $(document).ready(function () {
        $('#form-create-chanel').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                if (res.error){
                    $('#create_chanel').removeAttr('disabled', 'disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                }
                else if (res.success) {
                    $('#create_chanel').removeAttr('disabled', 'disabled');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL + '/chanel-manager');
                    });
                }
                else {
                    $('#create_chanel').removeAttr('disabled', 'disabled');
                    Swal.fire(
                        'Error!',
                        res.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
        $('#create_chanel').click(function (e){
            e.preventDefault();
            if($('#form-create-chanel').valid() == true) {
                $('#create_chanel').attr('disabled', 'disabled');
                $('#form-create-chanel').submit();
            }
        });
    });

    // Click input upload avatar create
    $('#btn-upload-avatar-create-chanel').click(function (e) {
        e.preventDefault();
        $('#avatar_chanel').click();
    });
    // Click input upload cover create
    $('#btn-upload-cover-create-chanel').click(function (e) {
        e.preventDefault();
        $('#cover_chanel').click();
    });
    // Show Avatar create
    $('#avatar_chanel').change(function (e) {
        e.preventDefault();
        let output = document.getElementById('show-avatar-chanel');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-avatar-create-chanel').html(e.target.files[0].name);
        $('#show-avatar-chanel').css('display', 'block');
    });
    // Show Cover create
    $('#cover_chanel').change(function (e) {
        e.preventDefault();
        let output = document.getElementById('show-cover-chanel');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-cover-create-chanel').html(e.target.files[0].name);
        $('#show-cover-chanel').css('display', 'block');
    });

    // Edit channel
    $(document).ready(function () {
        let href = window.location.href;
        $('#form-edit-chanel').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    $('#btn-edit-channel').removeAttr("disabled");
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else if (res.success) {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', href);
                    });
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        res.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
        $('#btn-edit-channel').click(function (e){
            e.preventDefault();
            if($('#form-edit-chanel').valid() == true){
                $('#btn-edit-channel').attr('disabled', 'disabled');
                $('#form-edit-chanel').submit();
            }

        });
    });
    // Click button edit
    $(document).on('click', '.edit-channel', function (e) {
        e.preventDefault();
        $('input[name="edit_hashtag_channel"]').tagsinput('removeAll');
        $('input[name="edit_relate_channel"]').tagsinput('removeAll');

        let id = $(this).attr('data-id');
        let name = $(this).attr('data-name');
        let hashtag = $(this).attr('data-hashtag');
        let des = $(this).attr('data-des');
        let relate = $(this).attr('data-relate');
        let kind = $(this).attr('data-kind');
        let avatar = $(this).attr('data-avatar');
        let cover = $(this).attr('data-cover');

        let relates = '';
        if (relate != '')
        {
            let arr_relate = relate.split(',');
            for (let i = 0; i < arr_relate.length; i++)
            {
                if(i == (arr_relate.length - 1))
                {
                    relates += 'https://digiclip.com.vn/channel/' + arr_relate[i];
                }else{
                    relates += 'https://digiclip.com.vn/channel/' + arr_relate[i] + ',';
                }
            }
        }

        $('input[name="edit_id_channel"]').val(id);
        $('input[name="edit_name_channel"]').val(name);
        $('input[name="edit_des_channel"]').val(des);
        $('select[name="edit_kind_channel"] option[value="'+ kind +'"]').attr("selected", 'selected');
        $('input[name="edit_hashtag_channel"]').tagsinput('add', hashtag);
        $('input[name="edit_relate_channel"]').tagsinput('add', relates);
        $('input[name="edit_avatar"]').val(avatar);
        $('input[name="edit_cover"]').val(cover);

        // alert('select[name="edit_kind_channel"] option[value="'+ kind +'"]');

        let output_a = document.getElementById('edit-show-avatar-chanel');
        output_a.src = avatar;
        let output_o = document.getElementById('edit-show-cover-chanel');
        output_o.src = cover;

        $('#edit_chanel').modal('show');
    });
    // Click input upload avatar adit
    $('#btn-upload-avatar-edit-chanel').click(function (e) {
        e.preventDefault();
        $('#edit_avatar_channel').click();
    });
    // Click input upload cover edit
    $('#btn-upload-cover-edit-chanel').click(function (e) {
        e.preventDefault();
        $('#edit_cover_channel').click();
    });
    // Show Avatar edit
    $('#edit_avatar_channel').change(function (e) {
        e.preventDefault();
        let output = document.getElementById('edit-show-avatar-chanel');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-avatar-edit-chanel').html(e.target.files[0].name);
        $('#edit-show-avatar-chanel').css('display', 'block');
    });
    // Show Cover edit
    $('#edit_cover_channel').change(function (e) {
        e.preventDefault();
        let output = document.getElementById('edit-show-cover-chanel');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-cover-edit-chanel').html(e.target.files[0].name);
        $('#edit-show-cover-chanel').css('display', 'block');
    });

    // Hidden channel
    $(document).on('click', '.hidden-channel', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let href = window.location.href;

        Swal.fire({
            title: 'Hide channel',
            text: 'Bạn muốn ẩn kênh?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/chanel-manager/hidden',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if(data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Suggest hide channel (Đề nghị ẩn)
    $(document).on('click', '.suggest-hide-channel', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let href = window.location.href;

        Swal.fire({
            title: 'Suggest hide channel',
            text: 'Bạn muốn đề nghị ẩn kênh?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/chanel-manager/suggest-hide',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if(data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Show channel
    $(document).on('click', '.show-channel', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let href = window.location.href;

        Swal.fire({
            title: 'Show channel',
            text: 'Bạn muốn hiện kênh?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/chanel-manager/show',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if(data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });

    // Approve checkbox channel
    $(document).on('click', '.approve-channel', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let id = $(this).attr('data-id');

        Swal.fire({
            title: 'Aprove channel',
            text: 'Bạn muốn phê duyệt kênh?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/chanel-manager/approve',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Approve checkbox channel
    $(document).on('change', '#approve-checkbox-channel', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let checkbox = '';
        $('input[name="checkbox_channel[]"]:checked').each(function(){
            checkbox = checkbox + ',' + $(this).val();
        });

        if (checkbox.length)
        {
            Swal.fire({
                title: 'Aprove channel',
                text: 'Bạn muốn phê duyệt các kênh?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.value) {
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });
                    $.ajax(BASE_URL + '/chanel-manager/approve-checkbox',{
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            ids: checkbox,
                        },
                        success:function (data){
                            console.log(data);
                            if (data.error){
                                Swal.fire({
                                    type: 'error',
                                    title: 'Error',
                                    text: data.error,
                                })
                            }
                            else if (data.success) {
                                Swal.fire(
                                    'Thành công!',
                                    data.success,
                                    'success'
                                ).then((value) => {
                                    $(location).attr('href', href);
                                });
                            }
                            else {
                                $('.footer .overlay').removeClass('overlaing');
                                Swal.fire(
                                    'Error!',
                                    data.logged,
                                    'error'
                                ).then((value) => {
                                    $(location).attr('href', BASE_URL);
                                });
                            }
                        }
                    });
                }
            });
        }else{
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy chọn kênh để phê duyệt!',
            });
        }
        $('select[name="approve-checkbox-channel"]').val('');
    });

    // Search channel change type
    $(document).on('change', '#search-channel-type', function (e) {
        e.preventDefault();
        let $this = $(this);
        let type = $('#search-channel-type').val();
        console.log(type);
        if(type == 0)
        {
            $('.box-search-acc-cp').removeClass('d-none');
            $('.box-search-acc-user').addClass('d-none');
            $('#staffId_cp').removeAttr('disabled', 'disabled');
            $('.box-search-acc-cp button.dropdown-toggle').removeClass('disabled');
            $('#staffId_user').attr('disabled', 'disabled');
        }
        else if (type == 1)
        {
            $('.box-search-acc-user').removeClass('d-none');
            $('.box-search-acc-cp').addClass('d-none');
            $('.box-search-acc-user .dropdown.bootstrap-select').removeClass('disabled');
            $('.box-search-acc-user .dropdown.bootstrap-select button.dropdown-toggle').removeClass('disabled');
            $('#staffId_user').removeAttr('disabled', 'disabled');
            $('#staffId_cp').attr('disabled', 'disabled');
        }
    });

    ////Channel ADMIN
    $(document).on('click', '#btn-create-channel', function (e) {
        e.preventDefault();
        $('#create_staffId_acc_cp').attr('disabled', 'disabled');
    });
    // Search channel account CP
    $(document).on('change', '#create_staffId_cp', function (e) {
        e.preventDefault();
        $('.channel-account-cp div').remove();
        let $this = $(this);
        let id = $('#create_staffId_cp').val();
        console.log(id);
        $('.channel-account-cp').html('<select name="staffId_acc_cp" id="create_staffId_acc_cp" required data-msg="Chưa chọn tài khoản" class="form-control" data-live-search="true" title="Chọn tài khoản CP"></select>');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/chanel-manager/search-acc-cp',{
            type: 'POST',
            dataType: 'json',
            data: {
                id: id,
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    $('#create_staffId_acc_cp').removeAttr('disabled');
                    $('#create_staffId_acc_cp').attr('title', data.error);
                    $('#create_staffId_acc_cp').selectpicker();
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else if(data.success) {
                    $('#create_staffId_acc_cp').attr('title', 'Chọn tài khoản CP');
                    $('#create_staffId_acc_cp').removeAttr('disabled');
                    $('#create_staffId_acc_cp').html(data.success);
                    $('#create_staffId_acc_cp').selectpicker();
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            },
            error: function(jqXHR,error, errorThrown) {
                $(location).attr('href', BASE_URL);
            }
        });
    });

    //Search account user load more
    function formatRepo (repo)
    {
        let markup = "<div class='select2-result-repository clearfix'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title'>" + repo.name + "</div></div></div>";
        return markup;
    }
    function formatRepoSelectionUserChannel (repo) {
        return repo.displayName || repo.text;
    }

    function matchCustom(params, data) {
        // If there are no search terms, return all of the data
        // console.log(params);
        // console.log(params.term);
        // if (!params.term || $.trim(params.term) === '' || params.term === null || params.term === 'undefined') {
            return data;
        // }

        // Do not display the item if there is no 'text' property
        // if (typeof data.text === 'undefined') {
        //     return null;
        // }

        // `params.term` should be the term that is used for searching
        // `data.text` is the text that is displayed for the data object
        // if (data.text.indexOf(params.term) > -1) {
        //     var modifiedData = $.extend({}, data, true);
        //     modifiedData.text += ' (matched)';
        //
        //     // You can return modified objects from here
        //     // This includes matching the `children` how you want in nested data sets
        //     return modifiedData;
        // }
        //
        // // Return `null` if the term should not be displayed
        // return null;
    }
    function formatState (state) {
        console.log(123);
        if (!state.id) {
            return state.text;
        }

        var baseUrl = "/user/pages/images/flags";
        var $state = $(
            '<span><img class="img-flag" /> <span></span></span>'
        );

        // Use .text() instead of HTML string concatenation to avoid script injection issues
        $state.find("span").text(state.text);
        $state.find("img").attr("src", baseUrl + "/" + state.element.value.toLowerCase() + ".png");

        return $state;
    };

    // Select ajax search account user
    $('.channel-js-data-user').select2({
        // matcher: matchCustom,
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/chanel-manager/get-user-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.data;
                        $.each(result, function( index, value ) {
                            value['id'] = value['userId'];
                            value['name'] = value['displayName'];
                        });
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.total
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Chọn tài khoản người sử dụng',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionUserChannel
    });

    // Set Vip Channel
    $(document).on('click', '.set-vip-channel', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let id = $(this).attr('data-id');
        let vip = $(this).attr('data-vip');
        let $this = $(this);
        let text = '';
        if (vip == true){
            text = 'Bạn muốn hủy kênh VIP?'
        }else{
            text = 'Bạn muốn set kênh VIP?'
        }
        Swal.fire({
            title: 'VIP',
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/chanel-manager/set-vip',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Delete channel
    $(document).on('click', '.btn-delete_channel', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let id = $(this).attr('data-id');

        Swal.fire({
            title: 'Xóa kênh?',
            text: "Bạn muốn xóa kênh này?!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/chanel-manager/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        } else if(data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else{
                            Swal.fire(
                                'error',
                                data.logged,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
})(jQuery);
