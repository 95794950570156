(function ($) {
    function formatRepo (repo)
    {
        var markup = "<div class='select2-result-repository clearfix'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title'>" + repo.name + "</div></div></div>";
        return markup;
    }
    // Select ajax search channel

    $('.js-data-channel').select2({
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/report/interactive/get-channel-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.data.content;
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.data.data.totalElements
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Lựa chọn kênh',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionChannel

    });
    function formatRepoSelectionChannel (repo) {
        return repo.name || repo.text;
    }
    // Select ajax search video
    function formatRepoVideo (repo)
    {
        let markup = "<div class='select2-result-repository clearfix'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title'>" + repo.title + "</div></div></div>";
        return markup;
    }
    $('.js-data-video').select2({
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/report/interactive/get-video-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.data.content;
                        $.each(result, function( index, value ) {
                            result['name'] = value['title'];
                        });
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.data.data.totalElements
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Lựa chọn video',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepoVideo,
        templateSelection: formatRepoSelectionVideo
    });
    function formatRepoSelectionVideo (repo) {
        return repo.title || repo.text;
    }
    // Select ajax search user use
    $('.js-data-user_use').select2({
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/report/interactive/get-user-use-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.data;
                        $.each(result, function( index, value ) {
                            value['id'] = value['userId'];
                            value['name'] = value['displayName'];
                        });
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.total
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Chọn tài khoản người sử dụng',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionUser

    });
    // Select ajax search user co-operate content
    $('.js-data-user_co-operate_content').select2({
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/report/interactive/get-user-content-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.data;
                        $.each(result, function( index, value ) {
                            value['id'] = value['userId'];
                            value['name'] = value['displayName'];
                        });
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.total
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Chọn tài khoản hợp tác nội dung',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionUser

    });
    function formatRepoSelectionUser (repo) {
        return repo.name || repo.text;
    }
    // Select ajax search CP
    $('.js-data-cp').select2({
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/report/interactive/get-cp-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.data;
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.total
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Lựa chọn CP',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionCP

    });
    function formatRepoSelectionCP (repo) {
        return repo.name || repo.text;
    }

    // Report hiệu quả tương tác theo nội dung: search change type
    $(document).on('change', '#report-search-type', function (e) {
        e.preventDefault();
        let $this = $(this);
        let type = $('#report-search-type').val();
        console.log(type);
        if(type == 1)
        {
            $('.search-select-ajax_channel').removeClass('d-none');
            $('.search-select-ajax_video').addClass('d-none');
            $('.js-data-channel').removeAttr('disabled', 'disabled');
            $('.js-data-video').attr('disabled', 'disabled');
        }
        else if (type == 2)
        {
            $('.search-select-ajax_video').removeClass('d-none');
            $('.search-select-ajax_channel').addClass('d-none');
            $('.js-data-video').removeAttr('disabled', 'disabled');
            $('.js-data-channel').attr('disabled', 'disabled');
        }
    });
    // Report hiệu quả tương tác theo người dùng: search change status
    $(document).on('change', '#report-search_user-status', function (e) {
        e.preventDefault();
        let $this = $(this);
        let status = $('#report-search_user-status').val();
        console.log(status);
        if(status == 0)
        {
            $('.search-select-ajax_user_use').removeClass('d-none');
            $('.search-select-ajax_user_co-operate_content').addClass('d-none');
            $('.js-data-user_use').removeAttr('disabled', 'disabled');
            $('.js-data-user_co-operate_content').attr('disabled', 'disabled');
        }
        else if (status == 2)
        {
            $('.search-select-ajax_user_co-operate_content').removeClass('d-none');
            $('.search-select-ajax_user_use').addClass('d-none');
            $('.js-data-user_co-operate_content').removeAttr('disabled', 'disabled');
            $('.js-data-user_use').attr('disabled', 'disabled');
        }
    });

    // Report total view: search change type
    $(document).on('change', '#report-search-total-view', function (e) {
        e.preventDefault();
        let $this = $(this);
        let type = $('#report-search-total-view').val();
        console.log(type);
        if(type == 1)
        {
            $('#report-search-mobile-total-view').removeClass('d-none');
            $('#report-search-user-total-view').addClass('d-none');
            $('#report-search-mobile-total-view input').removeAttr('disabled', 'disabled');
            $('#report-search-user-total-view select').attr('disabled', 'disabled');
        }
        else if (type == 2)
        {
            $('#report-search-user-total-view').removeClass('d-none');
            $('#report-search-mobile-total-view').addClass('d-none');
            $('#report-search-user-total-view select').removeAttr('disabled', 'disabled');
            $('#report-search-mobile-total-view input').attr('disabled', 'disabled');
        }
    });

    // Export
    $(document).on('click', '.btn-export-package-theo-goi', function (e) {
        e.preventDefault();
        // $('.footer .overlay').addClass('overlaing');
        var from = $(this).attr('data-from');
        var to = $(this).attr('data-to');
        var type = $(this).attr('data-type');
        var href = window.location.href;

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/report/package/export-theo-goi',{
            type: 'GET',
            dataType: 'json',
            data: {
                from: from,
                to: to,
                type: type,
            },
            success:function (data){
                console.log(data);
                // window.location.href = data;
            }
        });
    });
    $(document).on('click', '.btn-export-package-theo-cu-phap', function (e) {
        e.preventDefault();
        // $('.footer .overlay').addClass('overlaing');
        var from = $(this).attr('data-from');
        var to = $(this).attr('data-to');
        var type = $(this).attr('data-type');
        var href = window.location.href;

        // Swal.fire({
        //     title: 'Suggest hide video',
        //     text: 'Bạn muốn đề nghị ẩn video?',
        //     type: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes',
        //     cancelButtonText: 'No',
        // }).then((result) => {
        //     if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/report/package/export-theo-cu-phap',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        from: from,
                        to: to,
                        type: type,
                    },
                    success:function (data){
                        // console.log(data);
                        // if (data.error){
                        //     $('.footer .overlay').removeClass('overlaing');
                        //     Swal.fire({
                        //         type: 'error',
                        //         title: 'Error',
                        //         text: data.error,
                        //     })
                        // }
                        // else if (data.success) {
                        //     $('.footer .overlay').removeClass('overlaing');
                        //     Swal.fire(
                        //         'Thành công!',
                        //         data.success,
                        //         'success'
                        //     ).then((value) => {
                        //         $(location).attr('href', href);
                        //     });
                        // }else {
                        //     $('.footer .overlay').removeClass('overlaing');
                        //     Swal.fire(
                        //         'Error!',
                        //         data.logged,
                        //         'error'
                        //     ).then((value) => {
                        //         $(location).attr('href', BASE_URL);
                        //     });
                        // }
                    }
                });
        //     }
        // });
    });
    $(document).on('click', '.btn-export-bac-thang', function (e) {
        e.preventDefault();
        // $('.footer .overlay').addClass('overlaing');
        var from = $(this).attr('data-from');
        var to = $(this).attr('data-to');
        var href = window.location.href;

        // Swal.fire({
        //     title: 'Suggest hide video',
        //     text: 'Bạn muốn đề nghị ẩn video?',
        //     type: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes',
        //     cancelButtonText: 'No',
        // }).then((result) => {
        //     if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/report/package/export-theo-bac-thang',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        from: from,
                        to: to,
                    },
                    success:function (data){
                        // console.log(data);
                        // if (data.error){
                        //     $('.footer .overlay').removeClass('overlaing');
                        //     Swal.fire({
                        //         type: 'error',
                        //         title: 'Error',
                        //         text: data.error,
                        //     })
                        // }
                        // else if (data.success) {
                        //     $('.footer .overlay').removeClass('overlaing');
                        //     Swal.fire(
                        //         'Thành công!',
                        //         data.success,
                        //         'success'
                        //     ).then((value) => {
                        //         $(location).attr('href', href);
                        //     });
                        // }else {
                        //     $('.footer .overlay').removeClass('overlaing');
                        //     Swal.fire(
                        //         'Error!',
                        //         data.logged,
                        //         'error'
                        //     ).then((value) => {
                        //         $(location).attr('href', BASE_URL);
                        //     });
                        // }
                    }
                });
        //     }
        // });
    });
    $(document).on('click', '.btn-export-tonghop', function (e) {
        e.preventDefault();
        // $('.footer .overlay').addClass('overlaing');
        var from = $(this).attr('data-from');
        var to = $(this).attr('data-to');
        var href = window.location.href;

        // Swal.fire({
        //     title: 'Suggest hide video',
        //     text: 'Bạn muốn đề nghị ẩn video?',
        //     type: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes',
        //     cancelButtonText: 'No',
        // }).then((result) => {
        //     if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/report/package/export-tong-hop',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        from: from,
                        to: to,
                    },
                    success:function (data){
                        // console.log(data);
                        // if (data.error){
                        //     $('.footer .overlay').removeClass('overlaing');
                        //     Swal.fire({
                        //         type: 'error',
                        //         title: 'Error',
                        //         text: data.error,
                        //     })
                        // }
                        // else if (data.success) {
                        //     $('.footer .overlay').removeClass('overlaing');
                        //     Swal.fire(
                        //         'Thành công!',
                        //         data.success,
                        //         'success'
                        //     ).then((value) => {
                        //         $(location).attr('href', href);
                        //     });
                        // }else {
                        //     $('.footer .overlay').removeClass('overlaing');
                        //     Swal.fire(
                        //         'Error!',
                        //         data.logged,
                        //         'error'
                        //     ).then((value) => {
                        //         $(location).attr('href', BASE_URL);
                        //     });
                        // }
                    }
                });
        //     }
        // });
    });

    $(document).on('click', '.btn_vde-thong-ke', function (e) {
        e.preventDefault();
        $('.footer .overlay').addClass('overlaing');
        let href = window.location.href;

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/download-vde-thong-ke',{
            type: 'GET',
            dataType: 'json',
            data: {},
            success:function (data){
                axios.get( data.api,
                    {
                        headers: {
                            'Content-Type': 'application/json',  'charset': 'utf-8',
                            'token': data.token
                        }
                    }
                ).then(function(data){
                    $('.footer .overlay').removeClass('overlaing');
                    console.log('SUCCESS!!');

                }).catch(function(){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: 'Có lỗi xảy ra. Vui lòng thử lại',
                    });
                });
            }
        });
    });
})(jQuery);
