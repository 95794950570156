(function ($) {
    $('#form-btn-create-notification').click(function (e){
        e.preventDefault();
        if($('#form-create-notification').valid() == true){
            $('#form-btn-create-notification').attr('disabled', 'disabled');
            $('#form-create-notification').submit();
        }
    });
    $('#form-create-notification').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('#form-btn-create-notification').removeAttr('disabled');
            $('.footer .overlay').removeClass('overlaing');
            if (res.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('#form-create-notification input').val('');
                $('#form-create-notification textarea').val('');
                $('#form-create-notification .note-editable').html('');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });

})(jQuery);
