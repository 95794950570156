
(function ($) {
    // Search video -- Suggest
    $('.config-video-search-general').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.success){
                $('.config-video-result').html('');
                setTimeout(function () {
                    let btnMore =  $('.playlist-search-loadmore');
                    $('.config-video-result').html(res.success);

                    btnMore.attr('data-search',res.searchKey);
                    btnMore.attr('data-start',res.start);
                    if (!res.loadmore){
                        btnMore.removeClass('loading');
                    }else {
                        btnMore.addClass('loading');
                    }
                },200)
            }
        }
    });
    //Add video
    $('.config-add-video-genneral').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.success){
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/general');
                });

            }
            else{
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
        }
    });
    // Remove video
    $(document).on('click', '.config-remove-video-general', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');

        Swal.fire({
            title: 'Remove video',
            text: 'Bạn muốn xóa video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/general/remove',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL + '/general');
                            });
                        }
                    }
                });
            }
        });
    })
})(jQuery);