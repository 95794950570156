(function ($) {
    $(document).on('change', '.add-alert-subscription', function (e) {
        e.preventDefault();
        let value = $(this).val();
        if (value == 5){
            $('.add-alert-text-phone').css('display', 'inline-grid');
        }else{
            $('.add-alert-text-phone').css('display', 'none');
        }
    });
    //Create Cast
    $('#form-btn-add-alert').click(function (e){
        e.preventDefault();
        if($('#form-add-alert').valid() == true) {
            $('#form-btn-add-alert').attr('disabled', 'disabled');
            $('.footer .overlay').addClass('overlaing');
            $('#form-add-alert').submit();
        }
    });
    //Loại bỏ dấu
    $(document).on('input', '.addContentAlert', function () {
        var txtEntered = false;
        if ($(this).val() !== "") {
            txtEntered = true;
        }
        // Check chuỗi rỗng
        if (txtEntered)
        {
            var string = $(this).val();
            // Loại bỏ dấu trong chuỗi
            var strAccents = string.split('');
            var strAccentsOut = new Array();
            var strAccentsLen = strAccents.length;
            var accents =    "ÀÁẢÃẠÂẦẤẨẪẬÄÅĂẮẰẲẴẶàáảãạâầấẩẫậäåăắằẳẵặÒÓỎÕỌÔỒỐỔỖỘÖØƠỜỚỞỠỢòóỏõọôồốổỗộöøơờớởỡợÈÉẺẼẸÊỀẾỂỄỆËèéẻẽẹêềếểễệëðÇçÐđÌÍỈĨỊÎÏìíỉĩịîïÙÚỦŨỤƯỪỨỬỮỰÛÜùúủũụưừứửữựûũÑñŠšŸÿýŽž";
            var accentsOut = "AAAAAAAAAAAAAAAAAAAaaaaaaaaaaaaaaaaaaaOOOOOOOOOOOOOOOOOOOoooooooooooooooooooEEEEEEEEEEEEeeeeeeeeeeeeeCcDdIIIIIIIiiiiiiiUUUUUUUUUUUUUuuuuuuuuuuuuuNnSsYyyZz";
            for (var y = 0; y < strAccentsLen; y++) {
                if (accents.indexOf(strAccents[y]) != -1) {
                    strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
                } else
                    strAccentsOut[y] = strAccents[y];
            }
            strAccentsOut = strAccentsOut.join('');

            $(this).val(strAccentsOut);
        }
    });
    $('#form-add-alert').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('#form-btn-add-alert').removeAttr('disabled');
            $('.footer .overlay').removeClass('overlaing');
            if (res.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                document.getElementById('form-add-alert').reset();
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    // Edit
    $(document).on('click', '.edit-alert', function (e) {
        e.preventDefault();
        $('.footer .overlay').addClass('overlaing');
        $('#edit_alert .edit-type-alert').html('');
        $('select[name="edit_idVideo"]').html('');

        let id = $(this).attr('data-id');
        let type = $(this).attr('data-type');
        let content = $(this).attr('data-content');
        let link = $(this).attr('data-link');
        let date = $(this).attr('data-date');
        let textType = '';

        let str = link.split("/");
        if (str[3]){
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/alert/detail-video',{
                type: 'POST',
                dataType: 'json',
                data: {
                    id: str[3],
                },
                success:function (data){
                    console.log(data);
                    if (data.error){
                        $('.footer .overlay').removeClass('overlaing');
                        Swal.fire({
                            type: 'error',
                            title: 'Error get detail video',
                            text: data.error,
                        })
                    }
                    else if (data.success) {
                        $('.footer .overlay').removeClass('overlaing');
                        if(type == 1){
                            textType = 'Các thuê bao đã trừ cước thành công trên dịch vụ trong 24h trước đó';
                        }
                        else if(type == 2){
                            textType = 'Các thuê bao đã đăng ký sử dụng dịch vụ';
                        }
                        else if(type == 3){
                            textType = 'Các thuê bao đang hoạt động trên dịch vụ';
                        }
                        else if(type == 4){
                            textType = 'Các thuê bao đăng ký nhận tin Video Alert';
                        }
                        $('input[name="edit_id_alert"]').val(id);
                        $('textarea[name="edit_content_alert"]').val(content);
                        $('select[name="edit_idVideo"]').html('<option value="'+ str[3] +'">'+ data.success +'</option>');
                        $('input[name="edit_date_alert"]').val(date);
                        $('#edit_alert .edit-type-alert').html(textType);

                        $('#edit_alert').modal('show');
                    }
                    else {
                        $('.footer .overlay').removeClass('overlaing');
                        Swal.fire(
                            'Error!',
                            data.logged,
                            'error'
                        ).then((value) => {
                            $(location).attr('href', BASE_URL);
                        });
                    }
                }
            });
        }else{
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'URL không tồn tại ID.',
            })
        }
    });
    $('#form-btn-edit-alert').click(function (e){
        e.preventDefault();
        if($('#form-edit-alert').valid() == true) {
            $('#form-btn-edit-alert').attr('disabled', 'disabled');
            $('.footer .overlay').addClass('overlaing');
            $('#form-edit-alert').submit();
        }
    });
    $('#form-edit-alert').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('#form-btn-edit-alert').removeAttr('disabled');
            $('.footer .overlay').removeClass('overlaing');
            if (res.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    // Approve Alert
    $(document).on('click', '.approve-alert', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let id = $(this).attr('data-id');

        Swal.fire({
            title: 'Duyệt Alert',
            text: 'Bạn muốn phê duyệt alert?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/alert/approve',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Reject alert
    $('#form-reject-alert').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            $('.footer .overlay').removeClass('overlaing');
            $('.form-btn-reject-alert').removeAttr('disabled', 'disabled');
            if (res.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.success) {
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
            else {
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $(document).on('click', '.form-btn-reject-alert', function (e){
        e.preventDefault();
        if($('#form-reject-alert').valid() == true) {
            $('.footer .overlay').addClass('overlaing');
            $('.form-btn-reject-alert').attr('disabled', 'disabled');
            $('#form-reject-alert').submit();
        }
    });
    // Click button reject video
    $(document).on('click', '.btn-reject-alert', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');

        $('#reject_alert input[name="id_alert_reject"]').val(id);

        $('#reject_alert').modal('show');
    });
    // Select ajax search video Active
    function formatRepoVideoActive (repo)
    {
        let markup = "<div class='select2-result-repository clearfix'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title'>" + repo.title + "</div></div></div>";
        return markup;
    }
    $('.js-data-video-active').select2({
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/alert/get-video-active-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.data.content;
                        $.each(result, function( index, value ) {
                            result['name'] = value['title'];
                        });
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.data.data.totalElements
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Lựa chọn video',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepoVideoActive,
        templateSelection: formatRepoSelectionVideoActive
    });
    function formatRepoSelectionVideoActive (repo) {
        return repo.title || repo.text;
    }
})(jQuery);
