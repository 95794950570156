(function ($) {
    //
    $('#form-time-off').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/time-off');
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $('#form-content-cp-vip-active').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/time-off');
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
})(jQuery);
