(function ($) {
    // Create Kind
    $('#form-create-kind').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            $('#btn-create-kind').removeAttr('disabled');
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/kind-manager');
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $('#btn-create-kind').click(function (e){
        e.preventDefault();
        if($('#form-create-kind').valid() == true) {
            $('#btn-create-kind').attr('disabled', 'disabled');
            $('#form-create-kind').submit();
        }
    });

    // Delete kind
    $(document).on('click', '.btn-delete_kind', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Delete kind?',
            text: "Bạn muốn Xóa thể loại này?!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/kind-manager/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        user_id: id
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                'Xóa thành công.',
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                                // window.location= BASE_URL + "/admin/index";
                            });
                        }
                    }
                });
            }
        });
    });

    // Click input upload image create
    $('#btn-upload-img-create-kind').click(function (e) {
        e.preventDefault();
        $('#img_kind').click();
    });
    // Show Image create
    $('#img_kind').change(function (e) {
        e.preventDefault();
        var output = document.getElementById('show-img-kind');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-create-kind').html(e.target.files[0].name);
        $('#show-img-kind').css('display', 'block');
    });

    // Edite kind
    $(document).ready(function () {
        var href = window.location.href;
        $('#form-edit-kind').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                $('#btn-edit-kind').removeAttr('disabled');
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else if (res.success) {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', href);
                    });
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        res.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
        $('#btn-edit-kind').click(function (e){
            e.preventDefault();
            if($('#form-edit-kind').valid() == true){
                $('#btn-edit-kind').attr('disabled', 'disabled');
                $('#form-edit-kind').submit();
            }

        });
    });

    // Click button edit
    $(document).on('click', '.btn-edit-kind', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var name = $(this).attr('data-name');
        var sort = $(this).attr('data-sort');
        var img = $(this).attr('data-img');
        $('input[name="id_kind"]').val(id);
        $('input[name="edit_name_kind"]').val(name);
        $('input[name="edit_sort"]').val(sort);
        $('input[name="old_img"]').val(img);

        var output_o = document.getElementById('edit-show-img-kind');
        output_o.src = img;

        $('#edit_kind').modal('show');
    });
    // Click input upload image edit
    $('#btn-upload-img-edit-kind').click(function (e) {
        e.preventDefault();
        $('#edit_img_kind').click();
    });
    // Show Image edit
    $('#edit_img_kind').change(function (e) {
        e.preventDefault();
        var output = document.getElementById('edit-show-img-kind');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-edit-kind').html(e.target.files[0].name);
        $('#edit-show-img-kind').css('display', 'block');
    });

    // Hidden kind
    $(document).on('click', '.hidden-kind', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Hidden category',
            text: 'Bạn muốn ẩn thể loại?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/kind-manager/hidden',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Show kind
    $(document).on('click', '.show-kind', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Show category',
            text: 'Bạn muốn hiện thể loại?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/kind-manager/show',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Show/Hide kind Supper App
    $(document).on('click', '.configKind_supperApp', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let sa = $(this).attr('data-sa');
        let href = window.location.href;
        let title = '';
        let text = '';
        if (sa == 'true'){
            title = 'Ẩn thể loại';
            text = 'Bạn muốn ẩn thể loại trên supper app?';
        }else{
            title = 'Hiện thể loại';
            text = 'Bạn muốn hiện thể loại trên supper app?';
        }

        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/kind-manager/config-supper-app',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
})(jQuery);
