
(function ($) {
    // Check logout
    // $(document).ready(function () {
    //     $.ajax(BASE_URL + '/checkLogout',{
    //         type: 'GET',
    //         dataType: 'json',
    //         data: {},
    //         success:function (data){
    //             var pathname = window.location.pathname;
    //             if (pathname != '' && pathname != '/' && pathname != '/login')
    //             {
    //                 if (data.error){
    //                     // Swal.fire(
    //                     //     'Error!',
    //                     //     data.error,
    //                     //     'error'
    //                     // ).then((value) => {
    //                         $(location).attr('href', BASE_URL + '/login');
    //                     // });
    //                 }
    //             }
    //         }
    //     });
    // });

    $(document).keypress(function (event) {
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if (keycode == '13') {
            $('#btn-login').click();
        }
    });
    (function () {
        $('.btn-loginWithCaptcha').click(function (e) {
            e.preventDefault();
            $('.login__form .user-mess').empty();
            $('.login__form .pass-mess').empty();
            $('.login__form .general-mess').empty();
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/signin-with-captcha', {
                type: 'POST',
                dataType: 'json',
                data: {
                    username: $('#username').val(),
                    password: $('#password').val(),
                    redirect: $('input[name="redirect"]').val(),
                    remember_acc: $('#remember-acc').is(':checked') ? 1 : 0,
                    captcha : $('#captcha').val(),
                },
                beforeSend: function () {
                    $('section.login').addClass('loging');
                },
                success: function (data) {

                    if (data.error && data.error.general) {
                        var err_user_mes = data.error['username'] ? data.error['username'] : '';
                        var err_mes_pass = data.error['password'] ? data.error['password'] : '';
                        var err_general_mes = data.error['general'] ? data.error['general'] : '';
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: data.error.general,
                        });

                        $(".imageCaptcha span").html('<img src="data:image/jpeg;base64,' +
                            data.error.res.other +
                            '"' +
                            'alt="image">');

                        $('.login__form .user-mess').append(err_user_mes);
                        $('.login__form .pass-mess').append(err_mes_pass);
                        $('.login__form .general-mess').append(err_general_mes);
                        $('section.login').removeClass('loging');
                    } else {
                        // $('section.login .overlay').html(data.success);
                        // setTimeout(function () {
                        $(location).attr('href', data.url)
                        // },500);
                    }
                }
            });
        });
    })();
    (function (){
        $('.imageCaptcha .btn-refresh').click(function (e){
            e.preventDefault();
            let uri = BASE_URL + '/get-captcha/' + $('#username').val()
            $.ajax(uri,{
                type: 'GET',
                dataType: 'json',
                success:function (data){

                    $(".imageCaptcha span").html(data.captcha);
                }
            });
        })
    })();
    (function () {
        $('.btn-login').click(function (e) {
            e.preventDefault();
            var count = $(this).attr('data-count');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });

            $('.login__form .user-mess').empty();
            $('.login__form .pass-mess').empty();
            $('.login__form .general-mess').empty();
            if (count>10){
                $.ajax(BASE_URL + '/signin-with-captcha', {
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        username: $('#username').val(),
                        password: $('#password').val(),
                        redirect: $('input[name="redirect"]').val(),
                        remember_acc: $('#remember-acc').is(':checked') ? 1 : 0,
                        captcha : $('#captcha').val(),
                    },
                    beforeSend: function () {
                        $('section.login').addClass('loging');
                    },
                    success: function (data) {
                        setTimeout(function () {
                            $('section.login').removeClass('loging');
                        }, 500);
                        if (data.error && data.error.general) {
                            var err_user_mes = data.error['username'] ? data.error['username'] : '';
                            var err_mes_pass = data.error['password'] ? data.error['password'] : '';
                            var err_general_mes = data.error['general'] ? data.error['general'] : '';
                            // Swal.fire({
                            //     type: 'error',
                            //     title: 'Error',
                            //     text: data.error.general,
                            // });
                            if (data.error.res && data.error.res.other){
                                $(".imageCaptcha span").html('<img src="data:image/jpeg;base64,' +
                                    data.error.res.other +
                                    '"' +
                                    'alt="image">');
                            }


                            $('.login__form .user-mess').append(err_user_mes);
                            $('.login__form .pass-mess').append(err_mes_pass);
                            $('.login__form .general-mess').append(err_general_mes);

                        } else {
                            // $('section.login .overlay').html(data.success);
                            // setTimeout(function () {
                            $(location).attr('href', data.url)
                            // },500);
                        }
                    }
                });
                return
            }
            $.ajax(BASE_URL + '/signin', {
                type: 'POST',
                dataType: 'json',
                data: {
                    username: $('#username').val(),
                    password: $('#password').val(),
                    redirect: $('input[name="redirect"]').val(),
                    remember_acc: $('#remember-acc').is(':checked') ? 1 : 0,
                },
                beforeSend: function () {
                    $('section.login').addClass('loging');
                },
                success: function (data) {
                    console.log(data);
                    if (data.error) {
                        var err_user_mes = data.error['username'] ? data.error['username'] : '';
                        var err_mes_pass = data.error['password'] ? data.error['password'] : '';
                        var err_general_mes = data.error['general'] ? data.error['general'] : '';

                        $('.login__form .user-mess').append(err_user_mes);
                        $('.login__form .pass-mess').append(err_mes_pass);
                        $('.login__form .general-mess').append(err_general_mes);
                        setTimeout(function () {
                            $('section.login').removeClass('loging');
                        }, 500);
                        var countLogin =  data.error.res ? (data.error.res.other ? 11 : 0) : 0;
                        let image = 'data:image/jpeg;base64,' + data.error.res.other;
                        if (countLogin>10){
                            $(".imageCaptcha span").html('<img src="' +
                                image +
                                '"' +
                                'alt="image">');
                            $('.btn-login').attr('data-count',countLogin);
                            $('#captcha').val('');
                            $('.captcha').show()
                        }
                    } else {
                        // $('section.login .overlay').html(data.success);
                        // setTimeout(function () {
                        $(location).attr('href', data.url)
                        // },500);
                    }
                }
            });
        });
    })();

    // SSO
    // if (window.location.pathname == '/login'){
    // // $('#login-sso').click(function (e) {
    // //     e.preventDefault();
    // //     $('section.login').addClass('loging');
    //     $(document).ready(function () {
    //         $.get('https://ssocp.vnpt.vn/SSO/SSOService.svc/user/RequestToken?callback=?',
    //             function (ssodata) {
    //                 console.log(ssodata);
    //                 // get url to logon page in case this operation fails
    //                 var logonPage = 'https://ssocp.vnpt.vn/SSO/Login.aspx?keyid=10020&URL=' + BASE_URL + '/login';
    //
    //                 if (ssodata.Status == 'SUCCESS') {
    //                     // verify the token is genuine
    //                     console.log(ssodata.Token);
    //                     $.ajax(BASE_URL + '/login-sso', {
    //                         type: "GET",
    //                         data: {type: "CheckUser", token: ssodata.Token},
    //                         contentType: "application/json; charset=utf-8",
    //                         dataType: "text",
    //                         success: function (data) {
    //                             console.log(data);
    //                             if (data.error) {
    //                                 $('section.login').removeClass('loging');
    //                                 Swal.fire({
    //                                     type: 'error',
    //                                     title: 'Error',
    //                                     text: data.error,
    //                                 });
    //                             } else {
    //                                 $('section.login').removeClass('loging');
    //                                 $(location).attr('href', BASE_URL + '/home');
    //                             }
    //                         }
    //                     }); // end ajax call
    //                 } else {
    //                     // user needs to logon to SSO service
    //                     $('section.login').removeClass('loging');
    //                     document.location = logonPage;
    //                 }
    //                 // tell jQuery to use JSONP
    //             }, 'jsonp');
    //     });
    // // });
    // }

})(jQuery);
